import React from "react";
import { Helmet } from "react-helmet";
import ParticleLine from "./particle_line/ParticleLine";
import BreadCrumbs from "./breadcrumbs/BreadCrumbs";
import { withTranslation } from "react-i18next";

class ImprintPage extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className={"hidden"}>
          <BreadCrumbs
            breadCrumbData={[{ title: "Impressum", url: "/imprint" }]}
          />
        </div>
        <Helmet>
          <title>IMPRESSUM | Triple-A Soft</title>
          <meta
            name="description"
            content={
              "Wir setzten durch agile und innovative Entwicklung dienende Digitalisierung mit den neusten Technologien um."
            }
          />
          <meta name="title" content="Impressum" />
          <meta name="robots" content="noodp,noydir" />

          <meta
            property="og:site_name"
            content="Triple-A Soft UG (Haftungsbeschränkt)"
          />
          <meta property="og:title" content="Impressum" />
          <meta
            property="og:description"
            content="Wir setzten durch agile und innovative Entwicklung dienende Digitalisierung mit den neusten Technologien um."
          />
          <meta property="og:url" content="https://www.aaa-soft.net" />
          <meta
            property="og:image:url"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content="Impressum" />
          <meta
            name="twitter:description"
            content="Wir setzten durch agile und innovative Entwicklung dienende Digitalisierung mit den neusten Technologien um."
          />
          <meta
            name="twitter:image"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <ParticleLine>
          <p className={"page-header"}>{t("titles.imprint")}</p>
        </ParticleLine>
        <div className={"grid grid-cols-15"}>
          <div className={"row-start-1 col-start-3 col-span-11 mb-4"}>
            <h1 className={"subheader-text hyphens-auto"}>Impressum</h1>
            <p className={"blue-text hyphens-auto"}>
              <b>Triple-A Soft UG (haftungsbeschränkt) </b>
              <br />
              Danzigerstraße 3 <br />
              88250 Weingarten <br />
              <br />
              Telefon: +49 (0) 751 951 264 03 <br />
              E-Mail: swoboda@aaa-soft.net <br />
              <br />
              <b>Geschäftsführer</b> <br />
              Martin Swoboda <br />
              Registergericht: Amtsgericht Ulm <br />
              Registernummer: HRB 740095 <br />
            </p>
            <br />
            <br />
            <p className={"blue-text"}>
              <b>§ 1 Haftungshinweis</b>
              <br />
              Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
              Haftung für die Inhalte externer Links. Für den Inhalt der
              verlinkten Seiten sind ausschließlich deren Betreiber
              verantwortlich.
              <br />
              <br />
              <b>§ 2 Formulierungshinweis</b>
              <br />
              Aus Gründen der besseren Lesbarkeit des Textes wurde, sofern es
              sich nicht um Zitate handelt, auf die Schreibweise „-er/-innen“
              verzichtet. Generell wurden stattdessen die Begriffe stets in der
              kürzeren, männlichen Schreibweise (z. B. Mitarbeiter) verwendet.
              An dieser Stelle wird mit Gültigkeit für die gesamte Website und
              alle Dokumente betont, dass dies als Synonym für die männliche und
              weibliche Form vereinfacht verwendet wurde und alle männlichen und
              weiblichen Personen gleichberechtigt angesprochen werden.
              <br />
              <br />
              <b>§ 3 Externe Links</b>
              <br />
              Diese Website enthält Verknüpfungen zu Websites Dritter („externe
              Links“). Diese Websites unterliegen der Haftung der jeweiligen
              Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der
              externen Links die fremden Inhalte daraufhin überprüft, ob etwaige
              Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine
              Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss
              auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der
              verknüpften Seiten. Das Setzen von externen Links bedeutet nicht,
              dass sich der Anbieter die hinter dem Verweis oder Link liegenden
              Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links
              ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße
              nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch
              derartige externe Links unverzüglich gelöscht.
              <br />
              <br />
              <b>§ 4 Urheber- und Leistungsschutzrechte / Copyright</b>
              <br />
              Der gesamte Inhalt der Website ist urheberrechtlich geschützt.
              Dies betrifft alle Texte, Bilder, Dateien und Gestaltungselemente.
              Keine der auf unserer Seite veröffentlichten Inhalte dürfen ohne
              ausdrückliche Genehmigung der Triple-A Soft UG
              (haftungsbeschränkt) reproduziert oder vervielfältigt werden, sei
              es im Internet, als Ausdruck/Fotokopie oder auf/in anderen Medien.
              <br />
              Die auf dieser Website veröffentlichten Inhalte unterliegen dem
              deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
              Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung
              bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder
              jeweiligen Rechteinhabers. Dies gilt insbesondere für
              Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung,
              Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder
              anderen elektronischen Medien und Systemen. Inhalte und Rechte
              Dritter sind dabei als solche gekennzeichnet. Die unerlaubte
              Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter
              Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung
              von Kopien und Downloads für den persönlichen, privaten und nicht
              kommerziellen Gebrauch ist erlaubt.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ImprintPage);
