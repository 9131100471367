import React from "react";
import config from "../../config/main.config";
import { Helmet } from "react-helmet";
import ParticleLine from "../particle_line/ParticleLine";
import { NavLink } from "react-router-dom";
import BreadCrumbs from "../breadcrumbs/BreadCrumbs";
import { withTranslation } from "react-i18next";

class AboutPage extends React.Component {
  state = {
    applicationWith: 1200
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.updateDimensions);
    }
    this.updateDimensions();
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateDimensions);
    }
  }

  updateDimensions = () => {
    this.setState({
      applicationWith: window.innerWidth
    });
  };

  render() {
    const { applicationWith } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className={"hidden"}>
          <BreadCrumbs
            breadCrumbData={[{ title: "Über uns", url: "/about" }]}
          />
        </div>
        <Helmet>
          <title>ÜBER UNS | Triple-A Soft</title>
          <meta name="title" content="Über uns" />
          <meta
            name="description"
            content={"Wir sagen was wir denken und tun was wir sagen."}
          />
          <meta name="robots" content="index,follow" />

          <meta
            property="og:site_name"
            content="Triple-A Soft UG (Haftungsbeschränkt)"
          />
          <meta property="og:title" content="Über uns" />
          <meta
            property="og:description"
            content="Wir sagen was wir denken und tun was wir sagen."
          />
          <meta property="og:url" content="https://www.aaa-soft.net" />
          <meta
            property="og:image:url"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta property="og:type" content="website" />
          <meta name="title" content="Über uns" />
          <meta name="twitter:title" content="Über uns" />
          <meta
            name="twitter:description"
            content="Wir sagen was wir denken und tun was wir sagen."
          />
          <meta
            name="twitter:image"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <ParticleLine>
          <h1>{t("header.mission")}</h1>
          <p className={"page-header"}>Ein starkes und passioniertes Team</p>
        </ParticleLine>
        <div className={"grid grid-cols-5 msw:grid-cols-15 pb-4"}>
          <div className={"row-start-1 col-start-2 col-span-3 msw:col-start-3 msw:col-span-6"}>
            <h2>{t("header.what_we_say")}</h2>
          </div>
          <div
            className={"row-start-2 col-start-2 col-span-3 msw:col-start-3 msw:col-span-5 msw:col-start-3 msw:col-span-6"}>
            <p className={"text-2"}>
              Unser starkes Team von hochmotivierten Entwicklern bildet das Herzstück von Triple-A Soft. Mit
              umfangreichem Fachwissen und Leidenschaft setzen wir innovative Lösungen um, die Ihre Erwartungen
              übertreffen. Von der Optimierung bestehender Prozesse bis zur Entwicklung maßgeschneiderter Webanwendungen
              begleiten wir Sie Schritt für Schritt auf dem Weg zur digitalen Transformation.
            </p>
            <p className={"text-2"}>
              Mit unserer tiefen Leidenschaft für die digitale Welt setzen wir innovative Technologien ein, um Ihre
              Visionen und Ziele zu verwirklichen. Unser Team zeichnet sich durch exzellente Kommunikation, Effizienz
              und Teamarbeit aus. Wenn Sie mit unserem starken Team von motivierten Entwicklern zusammenarbeiten, können
              Sie sich darauf verlassen, dass Ihre Projekte in den besten Händen sind. Kontaktieren Sie uns noch heute,
              um mehr über unser Team und die Möglichkeiten der digitalen Transformation zu erfahren. Gemeinsam
              erreichen wir Großes!
            </p>
          </div>
          <div className={"max-msw:hidden msw:row-start-1 msw:row-span-2 msw:col-start-9 msw:col-span-5"}>
            <picture>
              <source
                media="(min-width: 1024px)"
                srcSet="images/desktop/TripleA-Grafik.webp, images/desktop/TripleA-Grafik.png"
              />
              <source
                media="(min-width: 321px)"
                srcSet="images/tablet/TripleA_Shopsysteme.webp, images/tablet/TripleA-Grafik.png"
              />
              <source
                media="(max-width: 320px)"
                srcSet="images/mobile/TripleA-Grafik.webp, images/mobile/TripleA-Grafik.png"
              />
              <img
                loading={"lazy"}
                className={"w-full"}
                src={"images/TripleA-Grafik.png"}
                alt={"No fallback images set"}
              />
            </picture>
          </div>
          <div className={"row-start-5 col-span-5 msw:col-span-15 grid grid-cols-8 mt-16"}>
            <div
              className={"row-start-1 col-start-1 col-span-8 msw:row-start-5 msw:col-start-1 msw:col-span-4 bg-cover max-msw:h-[30vw]"}
              style={{ backgroundImage: `url(${config.PUBLIC_URL}images/engagement.webp)` }}>
            </div>
            <div
              className={"row-start-2 col-start-1 col-span-8 msw:row-start-5 msw:col-start-5 msw:col-span-4 bg-secondary_dark_color h-fit p-8 grid grid-cols-2"}>
              <div
                className={"row-start-1 col-start-1 col-span-2 msw:row-start-1 msw:col-start-1 msw:col-span-1"}>
                <h3 className={"text-white pt-[4rem]"}>
                  Engagement
                </h3>
              </div>
              <div className={"row-start-2 col-start-1 col-span-2 msw:row-start-2 msw:col-start-1 msw:col-span-2"}>
                <p className={"text-2-white"} style={{ columnCount: applicationWith > 640 ? 2 : 1 }}>
                  Unsere Kunden zählen auf unser digitales Leistungsspektrum. Der Wandel zeigt,
                  dass auch Vereine und Stiftungen stetig mehr Bedarf haben sich überzeugend und
                  modern zu präsentieren. Hier möchten wir unterstützend wirken und garantieren,
                  dass eine dauerhaft ansprechende Webpräsenz gewährleistet wird.
                </p>
              </div>
              <div className={"row-start-3 col-start-1 col-span-2 msw:row-start-3 msw:col-start-1 msw:col-span-2"}>
                <NavLink to={"/engagement"}>
                  <button
                    className={"button-1 w-fit mt-[3vh] mb-[4rem]"}
                  >
                    {t("titles.engagement").toUpperCase()}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AboutPage);

/*
 {applicationWith > 991.5 ?
            <div className={"row-start-3 col-span-15 grid grid-cols-15"}>
              <div className={"row-start-3 col-start-2 col-span-6"}>
                <h4 className={"text-center"}>{t("header.the_team")}</h4>
              </div>
              <div className={"row-start-3 col-start-9 col-span-6"}>
                <h4 className={"text-center"}>
                  {t("header.the_network")}
                </h4>
              </div>
              <div className={"msw:row-start-4 msw:col-start-2 msw:col-span-13 flex flex-row items-center"}>
                <div className={"max-card:card-image-container w-[20%] h-[100%]"}>
                  <Card
                    cardImageLink={""}
                    roundedImage={true}
                    cardDescription={[
                      "Geschäftsführer",
                      <br />,
                      "M.Sc. IT Security"
                    ]}
                    cardImageSrc={"images/team/TRIPLE-A_martin_swoboda.jpg"}
                    cardHeader={"MARTIN SWOBODA"}
                    noBorder={true}
                  />
                </div>
                <div className={"max-card:card-image-container w-[20%] h-[100%]"}>
                  <Card
                    cardImageLink={""}
                    roundedImage={true}
                    cardDescription={[
                      "Professor Dr.-Ing.",
                      <br />,
                      "Konzeption, Technologien, Innovation"
                    ]}
                    noBorder={true}
                    cardImageSrc={"images/team/TRIPLE-A_thorsten_weiss.jpg"}
                    cardHeader={"THORSTEN WEISS"}
                  />
                </div>
                <div className={"max-msw:hidden team-separator w-[20%] h-[100%]"}>
                  <svg
                    style={{ strokeWidth: "10px" }}
                    viewBox="0 0 9 600"
                    width={"9"}
                    height={"600"}>
                    <line x1="5"
                          y1="0"
                          x2="5"
                          y2="600"
                          stroke="#EF9613"
                    />
                    Sorry, your browser does not support SVG.
                  </svg>
                </div>
                <div className={"max-card:card-image-container w-[20%] h-[100%]"}>
                  <Card
                    cardImageLink={""}
                    roundedImage={true}
                    cardDescription={[
                      "Agentur Inhalt",
                      <br />,
                      "Konzeption, Redaktion, Publikation"
                    ]}
                    noBorder={true}
                    cardImageSrc={
                      "images/team/TRIPLE-A_AgenturInhalt_Joachim_Dufner.jpg"
                    }
                    cardHeader={"JOACHIM DUFNER"}
                  />
                </div>
                <div className={"max-card:card-image-container w-[20%] h-[100%]"}>
                  <Card
                    cardImageLink={""}
                    roundedImage={true}
                    cardDescription={"UI/UX Design & Entwicklung"}
                    noBorder={true}
                    cardImageSrc={"images/team/TRIPLE-A_simon_schlecker.jpg"}
                    cardHeader={"SIMON SCHLECKER"}
                  />
                </div>
              </div>
            </div>
            :
            <div className={"row-start-3 col-start-1 col-span-5 md:col-start-2 md:col-span-3"}>
              <div className={"flex flex-col items-center"}>
                <div className={"w-[80%]"}>
                  <h4 className={"text-center"}>{t("header.the_team")}</h4>
                </div>
                <div className={"max-card:card-image-container w-[80%] h-[100%]"}>
                  <Card
                    cardImageLink={""}
                    roundedImage={true}
                    cardDescription={[
                      "Geschäftsführer",
                      <br />,
                      "M.Sc. IT Security"
                    ]}
                    cardImageSrc={"images/team/TRIPLE-A_martin_swoboda.jpg"}
                    cardHeader={"MARTIN SWOBODA"}
                    noBorder={true}
                  />
                </div>
                <div className={"max-card:card-image-container w-[80%] h-[100%]"}>
                  <Card
                    cardImageLink={""}
                    roundedImage={true}
                    cardDescription={[
                      "Professor Dr.-Ing.",
                      <br />,
                      "Konzeption, Technologien, Innovation"
                    ]}
                    noBorder={true}
                    cardImageSrc={"images/team/TRIPLE-A_thorsten_weiss.jpg"}
                    cardHeader={"THORSTEN WEISS"}
                  />
                </div>
                <div className={"w-[80%]"}>
                  <h4 className={"text-center"}>
                    {t("header.the_network")}
                  </h4>
                </div>
                <div className={"max-card:card-image-container w-[80%] h-[100%]"}>
                  <Card
                    cardImageLink={""}
                    roundedImage={true}
                    cardDescription={[
                      "Agentur Inhalt",
                      <br />,
                      "Konzeption, Redaktion, Publikation"
                    ]}
                    noBorder={true}
                    cardImageSrc={
                      "images/team/TRIPLE-A_AgenturInhalt_Joachim_Dufner.jpg"
                    }
                    cardHeader={"JOACHIM DUFNER"}
                  />
                </div>
                <div className={"max-card:card-image-container w-[80%] h-[100%]"}>
                  <Card
                    cardImageLink={""}
                    roundedImage={true}
                    cardDescription={"UI/UX Design & Entwicklung"}
                    noBorder={true}
                    cardImageSrc={"images/team/TRIPLE-A_simon_schlecker.jpg"}
                    cardHeader={"SIMON SCHLECKER"}
                  />
                </div>
              </div>
            </div>}
 */
