import React from "react";
import { Helmet } from "react-helmet";
import ParticleLine from "./particle_line/ParticleLine";
import BreadCrumbs from "./breadcrumbs/BreadCrumbs";
import { withTranslation } from "react-i18next";

class DataPrivacy extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <Helmet>
          <title>DATENSCHUTZ | Triple-A Soft</title>
          <meta
            name="description"
            content={
              "Wir setzten durch agile und innovative Entwicklung dienende Digitalisierung mit den neusten Technologien um."
            }
          />
          <meta name="robots" content="noodp,noydir" />
          <meta name="title" content="Datenschutz" />

          <meta
            property="og:site_name"
            content="Triple-A Soft UG (Haftungsbeschränkt)"
          />
          <meta property="og:title" content="Datenschutz" />
          <meta
            property="og:description"
            content="Wir setzten durch agile und innovative Entwicklung dienende Digitalisierung mit den neusten Technologien um."
          />
          <meta property="og:url" content="https://www.aaa-soft.net" />
          <meta
            property="og:image:url"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content="Datenschutz" />
          <meta
            name="twitter:description"
            content="Wir setzten durch agile und innovative Entwicklung dienende Digitalisierung mit den neusten Technologien um."
          />
          <meta
            name="twitter:image"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <div className={"hidden"}>
          <BreadCrumbs
            breadCrumbData={[{ title: "Datenschutz", url: "/data-privacy" }]}
          />
        </div>
        <ParticleLine>
          <p className={"page-header"}>{t("titles.data_privacy")}</p>
        </ParticleLine>
        <div className={"grid grid-cols-15"}>
          <div className={"row-start-1 col-start-3 col-span-11 mb-4"}>
            <h1 className={"subheader-text"}>Datenschutzerklärung</h1>
            <p className={"text-2"}>
              Verantwortliche Stelle im Sinne der Datenschutzgesetze,
              insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:
            </p>
            <p className={"text-2"}>
              Martin Swoboda
              <br />
              Triple-A Soft UG (haftungsbeschränkt)
              <br />
              Danzigerstraße 3<br />
              88250 Weingarten <br />
            </p>
            <h4>Ihre Betroffenenrechte</h4>
            <p className={"text-2"}>
              Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
              können Sie jederzeit folgende Rechte ausüben:
            </p>
            <ul>
              <li>
                Auskunft über Ihre bei uns gespeicherten Daten und deren
                Verarbeitung,
              </li>
              <li>Berichtigung unrichtiger personenbezogener Daten,</li>
              <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
              <li>
                Einschränkung der Datenverarbeitung, sofern wir Ihre Daten
                aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,
              </li>
              <li>
                Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und
              </li>
              <li>
                Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
                eingewilligt haben oder einen Vertrag mit uns abgeschlossen
                haben.
              </li>
            </ul>
            <p className={"text-2"}>
              Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
              jederzeit mit Wirkung für die Zukunft widerrufen.
            </p>
            <p className={"text-2"}>
              Sie können sich jederzeit mit einer Beschwerde an die für Sie
              zuständige Aufsichtsbehörde wenden. Ihre zuständige
              Aufsichtsbehörde richtet sich nach dem Bundesland Ihres
              Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung. Eine
              Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich)
              mit Anschrift finden Sie unter:{" "}
              <a
                href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
              </a>
              .
            </p>
            <h4>
              Zwecke der Datenverarbeitung durch die verantwortliche Stelle und
              Dritte
            </h4>
            <p className={"text-2"}>
              Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser
              Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer
              persönlichen Daten an Dritte zu anderen als den genannten Zwecken
              findet nicht statt. Wir geben Ihre persönlichen Daten nur an
              Dritte weiter, wenn:
            </p>
            <ul>
              <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
              <li>
                die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen
                erforderlich ist,
              </li>
              <li>
                die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
                erforderlich ist,
              </li>
            </ul>
            <p className={"text-2"}>
              die Verarbeitung zur Wahrung berechtigter Interessen erforderlich
              ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes
              schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.
            </p>
            <h4>Löschung bzw. Sperrung der Daten</h4>
            <p className={"text-2"}>
              Wir halten uns an die Grundsätze der Datenvermeidung und
              Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher
              nur so lange, wie dies zur Erreichung der hier genannten Zwecke
              erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen
              vielfältigen Speicherfristen vorsehen. Nach Fortfall des
              jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die
              entsprechenden Daten routinemäßig und entsprechend den
              gesetzlichen Vorschriften gesperrt oder gelöscht.
            </p>
            <h4>
              Erfassung allgemeiner Informationen beim Besuch unserer Website
            </h4>
            <p className={"text-2"}>
              Wenn Sie auf unsere Website zugreifen, werden automatisch mittels
              eines Cookies Informationen allgemeiner Natur erfasst. Diese
              Informationen (Server-Logfiles) beinhalten etwa die Art des
              Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
              Internet-Service-Providers und ähnliches. Hierbei handelt es sich
              ausschließlich um Informationen, welche keine Rückschlüsse auf
              Ihre Person zulassen.
            </p>
            <p className={"text-2"}>
              Diese Informationen sind technisch notwendig, um von Ihnen
              angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen
              bei Nutzung des Internets zwingend an. Sie werden insbesondere zu
              folgenden Zwecken verarbeitet:
            </p>
            <ul>
              <li>
                Sicherstellung eines problemlosen Verbindungsaufbaus der
                Website,
              </li>
              <li>
                Sicherstellung einer reibungslosen Nutzung unserer Website,
              </li>
              <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
              <li>zu weiteren administrativen Zwecken.</li>
            </ul>
            <p className={"text-2"}>
              Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem
              berechtigten Interesse aus den vorgenannten Zwecken zur
              Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf
              Ihre Person zu ziehen. Empfänger der Daten sind nur die
              verantwortliche Stelle und ggf. Auftragsverarbeiter.
            </p>
            <p className={"text-2"}>
              Anonyme Informationen dieser Art werden von uns ggfs. statistisch
              ausgewertet, um unseren Internetauftritt und die dahinterstehende
              Technik zu optimieren.
            </p>
            <h4>SSL-Verschlüsselung</h4>
            <p className={"text-2"}>
              Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
              verwenden wir dem aktuellen Stand der Technik entsprechende
              Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
            </p>
            <h4>Kontaktformular</h4>
            <p className={"text-2"}>
              Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder
              Kontaktformular mit uns in Kontakt, erteilen Sie uns zum Zwecke
              der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür ist die
              Angabe einer validen E-Mail-Adresse erforderlich. Diese dient der
              Zuordnung der Anfrage und der anschließenden Beantwortung
              derselben. Die Angabe weiterer Daten ist optional. Die von Ihnen
              gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage
              sowie für mögliche Anschlussfragen gespeichert. Nach Erledigung
              der von Ihnen gestellten Anfrage werden personenbezogene Daten
              automatisch gelöscht.
            </p>
            <h4>
              <strong>Änderung unserer Datenschutzbestimmungen</strong>
            </h4>
            <p className={"text-2"}>
              Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit
              sie stets den aktuellen rechtlichen Anforderungen entspricht oder
              um Änderungen unserer Leistungen in der Datenschutzerklärung
              umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren
              erneuten Besuch gilt dann die neue Datenschutzerklärung.
            </p>
            <h4>
              <strong>Fragen an den Datenschutzbeauftragten</strong>
            </h4>
            <p className={"text-2"}>
              Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte
              eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz
              verantwortliche Person in unserer Organisation:
            </p>
            <p className={"text-2"}>swoboda@aaa-soft.net</p>
            <p>
              <em>Die Datenschutzerklärung wurde mit dem </em>
              <a
                href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/"
                target="_blank"
                rel="noreferrer"
              >
                <em>
                  Datenschutzerklärungs-Generator der activeMind AG erstellt
                </em>
              </a>
              <em>.</em>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DataPrivacy);
