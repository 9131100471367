import React from 'react';
import { Helmet } from 'react-helmet';

class MetaTags extends React.Component {
  render() {
    return (
      <Helmet>
        {/*<!-- Organisation -->*/}
        <script type="application/ld+json">
          {`{
                        "@context" : "http://schema.org",
                        "@type" : "Organization",
                        "legalName" : "Triple-A Soft UG (Haftungsbeschränkt)",
                        "name" : "Triple-A Soft",
                        "alternateName" : ["aaa soft",
                        "triple a soft"],
                        "address": {
                            "@type": "PostalAddress",
                            "addressCountry": "Deutschland",
                            "addressLocality": "Weingarten",
                            "addressRegion": "Baden-Württemberg",
                            "name": "Triple-A Soft UG (Haftungsbeschränkt)",
                            "streetAddress": "Danzigerstraße 3",
                            "postalCode": "88250"
                        },
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "name": "Callcenter",
                            "telephone": "+49 751 951 264 03",
                            "email": "swoboda@aaa-soft.net",
                            "contactType": "customer support"
                        },
                        "email" : "swoboda@aaa-soft.net",
                        "logo" :  "https://aaa-soft.net/images/logo.svg",
                        "url" : "https://www.aaa-soft.net/",
                        "sameAs" : ["https://www.facebook.com/Triple-A-Soft-UG-100335381759106"]
                        }`}
        </script>

        {/*<!-- Local Business -->*/}
        <script type="application/ld+json">
          {`{
                        "@context": "http://schema.org",
                        "@type": "LocalBusiness",
                         "address": {
                            "@type": "PostalAddress",
                            "addressCountry": "Deutschland",
                            "addressLocality": "Weingarten",
                            "addressRegion": "Baden-Württemberg",
                            "name": "Triple-A Soft UG (Haftungsbeschränkt)",
                            "streetAddress": "Danzigerstraße 3",
                            "postalCode": "88250"
                        },
                        "description": "Entwicklung von Webanwendungen und Beratung von Digitalisierung und IT-Security",
                        "url" : "https://www.aaa-soft.net/",
                        "name" : "Triple-A Soft",
                        "telephone": "+49 751 951 264 03",
                        "openingHours": "Mo,Tu,We,Th,Fr 09:00-12:00 13:00-16:00",
                          "priceRange" : "€€",
                        "image" : ["https://aaa-soft.net/images/logo.svg"],
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "47.8182834",
                            "longitude": "9.6431021"
                        },
                        "sameAs" : ["https://www.facebook.com/Triple-A-Soft-UG-100335381759106"]
                    }`}
        </script>
      </Helmet>
    );
  }
}

export default MetaTags;
