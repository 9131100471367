/**
 * Created by Matijas on 11.04.2017.
 */

import { isDevelop } from '../helper/util';

export default {
  BASE_URL: isDevelop()
    ? `http://localhost:${process.env.APP_PORT}/api/`
    : '/api/',
  PUBLIC_URL: isDevelop() ? `http://localhost:${process.env.APP_PORT}/` : '/',
  STORAGE_KEY: '4WfG5jv3',
  USER_STORAGE_KEY: 'cjhSopcv0w9'
};
