import React from "react";
import config from "../../config/main.config";
import PropTypes from "prop-types";

class Card extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardHeader: this.props.cardHeader,
      cardDescription: this.props.cardDescription,
      cardImageSrc: this.props.cardImageSrc,
      cardImageLink: this.props.cardImageLink,
      noBorder: this.props.noBorder,
      cardBackground: this.props.cardBackground,
      roundedImage: this.props.roundedImage
    };
  }

  render() {
    let {
      cardHeader,
      cardDescription,
      cardImageSrc,
      cardImageLink,
      noBorder,
      roundedImage,
      cardBackground
    } = this.state;
    return (
      <div className={noBorder ? "card-container-no-border" : "card-container"}>
        <div className={(cardImageLink === "" ? "disable-card image  card-image-container" : "image  card-image-container") + " bg-transparent p-[20px]"}>
          <img
            loading={"lazy"}
            className={"max-sm:min-w-[30vw]  p-[15px] max-md:p-[5vw]"}
            src={config.PUBLIC_URL + cardImageSrc}
            alt={""}
            style={
              cardBackground !== "" || !roundedImage
                ? {
                  backgroundImage: "url(" + cardBackground + ")",
                  backgroundSize: "contain",
                  borderRadius: "20px"
                }
                : { borderRadius: "100%"}
            }
          />
        </div>
        <div className={"content border-none pt-0"}>
          <div className={"header-5"}>{cardHeader}</div>
          <div className={"text-2 text-center"}>
            {cardDescription}
          </div>
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  cardHeader: PropTypes.string,
  cardDescription: PropTypes.string,
  cardImageSrc: PropTypes.string,
  cardImageLink: PropTypes.string,
  cardBackground: PropTypes.string,
  noBorder: PropTypes.bool,
  roundedImage: PropTypes.bool
};

Card.defaultProps = {
  cardHeader: "",
  cardDescription: "",
  cardImageSrc: "",
  cardImageLink: "",
  cardBackground: "",
  noBorder: false,
  roundedImage: false
};

export default Card;
