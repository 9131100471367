import React from 'react';
import config from '../../config/main.config';

export default class ScrollButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false,
      fadeUp: false
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener('scroll', function(e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    if (window.pageYOffset > 800) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
        fadeUp: false
      });
    }
  }

  toggleFadeUp() {
    this.setState(
      { fadeUp: true }
    );
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  render() {
    return (
      <div className={"scroll_to_top "}>
        <div className={this.state.is_visible ? "animate-fade-in-down duration-300" : "hidden"}>
          <div onClick={() => {
            this.toggleFadeUp();
            this.scrollToTop();
          }}>

          <img
              loading={"lazy"}
              alt={"Scroll Button"}
              className={this.state.fadeUp ? "animate-fade-out-up duration-200 scroll_up_button_image" : "scroll_up_button_image"}
              src={config.PUBLIC_URL + "images/rocket.svg"}
          />
          </div>
        </div>
      </div>
    );
  }
}
