export default {
  LANGUAGES: [
    {
      language: 'de-DE',
      label: 'Deutsch',
      regexToSearch: '/'
    },
    {
      language: 'en-UK',
      label: 'English',
      regexToSearch: /^\/en-UK*/
    }
  ],

  IMAGE_SIZES: {
    LARGE: 1920,
    MEDIUM: 1024,
    SMALL: 320
  }
};
