import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import { withTranslation } from "react-i18next";

const CookieBanner = () => {
  const [cookies, setCookie] = useCookies();
  const [marketing, setMarketing] = useState(
    cookies.consentGiven && cookies.consentGiven.includes("marketing")
  );
  const [statistic, setStatistic] = useState(
    cookies.consentGiven && cookies.consentGiven.includes("statistic")
  );
  const [performance, setPerformance] = useState(
    cookies.consentGiven && cookies.consentGiven.includes("performance")
  );
  const [consent, setConsent] = useState(
    cookies.consentGiven && cookies.consentGiven.includes("required")
  );

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!cookies.consentGiven) {
      setConsent(false);
    } else {
      setConsent(cookies.consentGiven.includes("required"));
    }
  }, [cookies]);

  useEffect(() => {
    setCookies(consent, performance, statistic, marketing, setCookie, cookies);
  }, [consent]);

  if (consent || !loaded) {
    return null;
  } else {
    return (
      <div className={"animate-fade-in-down duration-300 fixed top-0 left-0 right-0 cookie_container"}>
        <div className={"Content"}>
          <div className={"grid grid-cols-9 gap-2 !z-[3] bg-white"}>
            <div className={"row-start-1 col-start-1 md:col-start-2 col-span-9 md:col-span-7 mt-4"}>
              <p className={"text-header-link-md"}><b>Diese Webseite verwendet Cookies</b></p>
            </div>
            <div className={"row-start-2 col-start-1 md:col-start-2 col-span-9 md:col-span-7"}>
              <p className={"text-2 mt-2"}>
                Diese Webseite verwendet Cookies, um die Funktionalität der
                Webseite bereitzustellen, unseren Traffic zu analysieren, und
                Ihr Browser-Erlebnis zu verbessern. Klicken Sie auf
                "Akzeptieren", um die Cookies zu akzeptieren. Um bestimmte
                Cookies auf der Website zu akzeptieren, wähle Sie eine der
                anderen unten aufgeführten Optionen. Sie können auch unsere{" "}
                <Link className={"underline"} to={"/data-privacy"}>Datenschutzrichtlinie</Link> und&nbsp;
                <Link className={"underline"} to={"/data-privacy"}>Cookie-Richtlinie</Link> lesen.
              </p>

            </div>
            <div
              className={"row-start-3 col-start-1 md:col-start-2 col-span-7 flex flex-row flex-wrap justify-items-center items-center cookie_container"}>
              <div className={"flex flex-row justify-items-center items-center"}>
                <input
                  type="checkbox"
                  checked={true}
                  disabled={true}
                  className="checkbox"
                  readOnly="true"
                  tabIndex="0"
                  aria-label="Notwendige"
                />
                <label>Notwendige</label>
              </div>
              <div className={"flex flex-row justify-items-center items-center"}>
                <input
                  type="checkbox"
                  className="checkbox"
                  readOnly=""
                  tabIndex="0"
                  onChange={e => {
                    setPerformance(e.target.checked);
                  }}
                  aria-label="Performance"
                />
                {/*Google Maps*/}
                <label>Performance</label>
              </div>
              <div className={"flex flex-row justify-items-center items-center"}>
                <input
                  type="checkbox"
                  className="checkbox"
                  readOnly=""
                  tabIndex="0"
                  onChange={e => {
                    setStatistic(e.target.checked);
                  }}
                  aria-label="Statistiken"
                />
                {/*Analytics*/}
                <label>Statistiken</label>
              </div>
            </div>
            <div
              className={"row-start-4 col-start-1 col-span-9 md:col-start-2 md:col-span-7 flex flex-col xl:flex-row flex-wrap justify-items-center items-center mt-4 mb-8"}>
              <button
                className="cookie-button"
                onClick={() => {
                  setConsent(true);
                }}
              >
                Cookie Einstellungen speichern
              </button>
              <button
                className="cookie-button"
                onClick={() => {
                  setStatistic(false);
                  setMarketing(false);
                  setPerformance(false);
                  setConsent(true);
                }}
              >
                nur notwendige Cookies
              </button>
              <button
                className="cookie-button-primary"
                onClick={() => {
                  setStatistic(true);
                  setMarketing(true);
                  setPerformance(true);
                  setConsent(true);
                }}
              >
                alle akzeptieren
              </button>
            </div>
          </div>
        </div>
      </div>
    )
      ;
  }
};

function setCookies(consent, performance, statistic, marketing, setCookie) {
  let content = "";
  if (consent) {
    if (performance) content += "performance, ";
    if (statistic) content += "statistic, ";
    if (marketing) content += "marketing, ";

    content += "required";
    setCookie("consentGiven", content, { path: "/", maxAge: 32000000 });
  }
}

export default withTranslation()(CookieBanner);
