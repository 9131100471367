import React, { useEffect, useState } from "react";
import config from "../../config/main.config";
import { NavLink } from "react-router-dom";
import { MAX_MOBILE_WIDTH } from "../../helper/util";
import { withTranslation } from "react-i18next";

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function ScrollFunction(setYPosition, yPosition) {
  const [mobileNavBar, setMobileNavBar] = useState({});

  function onScroll() {
    setYPosition(window.pageYOffset);
    if (window.innerWidth <= MAX_MOBILE_WIDTH) {
      if (yPosition > window.pageYOffset || window.pageYOffset <= 0) {
        setMobileNavBar({ top: "0" });
      } else {
        setMobileNavBar({ top: "-300px" });
      }
    }
    yPosition = window.pageYOffset;
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    onScroll();
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return mobileNavBar;
}

const NavigationBar = props => {
  let oldPos = 0;
  const [showMenu, setShowMenu] = useState(false);
  const [yPosition, setYPosition] = useState(0);
  const applicationWith = useWindowSize().width;
  const mobileNavBar = ScrollFunction(setYPosition, oldPos);
  const { t } = props;

  return (
    <nav className={""}>
      {applicationWith <= MAX_MOBILE_WIDTH ? (
        <div
          className={"mobile-navigation-menu"}
          style={mobileNavBar}
        >
          <div className={"grid grid-cols-1 mini:grid-cols-2 small:grid-cols-3 w-full"}>
            <div className={"row-start-1 mini:row-start-1 col-start-1 col-span-1 self-center"}>
              <NavLink
                exact
                to="/"
                onClick={() => setShowMenu(false)}
                className={"nav_link"}
              >
                <img
                  loading={"lazy"}
                  src={config.PUBLIC_URL + "images/company_logos/logo.svg"}
                  alt={"Company Logo"}
                  className={"navigation_image_small mt-[-35px] mb-[-35px]"}
                />
              </NavLink>
            </div>
            <div
              className={"row-start-3 col-start-1 mini:row-start-1 mini:col-start-2 small:col-start-2 col-span-1 p-0 self-center mini: m-4 small:m0 mr-4 justify-self-center mini:justify-self-end small:justify-self-center"}>
              <div
                id={"nav-icon3"}
                className={showMenu ? "open" : ""}
                onClick={() => setShowMenu(!showMenu)}>
                <span />
                <span />
                <span />
                <span />
              </div>
            </div>
            <div className={"row-start-2 mini:row-start-2 col-start-1 small:row-start-1 small:col-start-3 col-span-1 self-center"}>
              <div className={"small:block hidden"}>
                <a href={"/contact"} onClick={() => setShowMenu(false)}>
                  <button className={"button-2 float-left small:float-right"}>
                    {t("titles.contact").toUpperCase()}
                  </button>
                </a>
              </div>
            </div>
            <div className={(showMenu ? "no_top_padding row-start-4 col-start-1 mini:row-start-2 mini:col-start-1 small:row-start-2 small:col-start-2 col-span-2 mini:col-span-2 small:col-span-1 text-center" : "hide_div")}>
              <div className={"button-2 h-[50px] w-[152px] mr-0 justify-self-center !text-white small:hidden"}>
                <NavLink
                  onClick={() => setShowMenu(false)}
                  exact
                  to="/contact"
                  className={(yPosition < 1 ? "nav_link" : "nav_link_scroll") + " mt-2 mb-2 align-middle !text-white"}
                >
                  {t("titles.contact")}
                </NavLink>
              </div>
              <NavLink
                onClick={() => setShowMenu(false)}
                exact
                to="/services"
                className={(yPosition < 1 ? "nav_link" : "nav_link_scroll") + " mt-2 mb-2"}
                activeClassName={"nav_link_active"}
              >
                {t("titles.services")}
              </NavLink>
              <NavLink
                onClick={() => setShowMenu(false)}
                exact
                to="/about"
                className={(yPosition < 1 ? "nav_link" : "nav_link_scroll") + " mb-2"}
                activeClassName={"nav_link_active"}
              >
                {t("titles.about")}
              </NavLink>
              <NavLink
                onClick={() => setShowMenu(false)}
                exact
                to="/faq"
                className={yPosition < 1 ? "nav_link" : "nav_link_scroll"}
                activeClassName={"nav_link_active"}
              >
                {t("titles.faq")}
              </NavLink>
            </div>
          </div>
        </div>
      ) : (
        <div className={"nav_row"}>
          <a href={"/"}>
            <img
              loading={"lazy"}
              src={config.PUBLIC_URL + "images/company_logos/logo.svg"}
              alt={"Company Logo"}
              className={"navigation_image"}
            />
          </a>
          <div className={"m-auto"}>
            <NavLink
              exact
              to="/services"
              className={"nav_link"}
              activeClassName={"nav_link_active"}
            >
              {t("titles.services")}
            </NavLink>
            <NavLink
              exact
              to="/about"
              className={"nav_link"}
              activeClassName={"nav_link_active"}
            >
              {t("titles.about")}
            </NavLink>
            <NavLink
              exact
              to="/faq"
              className={"nav_link"}
              activeClassName={"nav_link_active"}
            >
              {t("titles.faq")}
            </NavLink>
          </div>
          <a href={"/contact"}>
            <button className={"button-2"}>
              {t("titles.contact").toUpperCase()}
            </button>
          </a>
        </div>
      )}
    </nav>
  );
};
export default withTranslation()(NavigationBar);
