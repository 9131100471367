import React, { useEffect, useMemo, useRef } from "react";

function createParticles(particleCount, width, height, particleSize, minSpeed, maxSpeed) {
  let particlePositions = [];
  for (let i = 0; i < particleCount; i++) {
    let x = particleSize + Math.random() * (width - particleSize);
    let y = particleSize + Math.random() * (height - (width / 12.5) - particleSize);
    let vel = {
      x: (minSpeed + Math.random() * (maxSpeed - minSpeed)) * (Math.round(Math.random()) ? 1 : -1),
      y: (minSpeed + Math.random() * (maxSpeed - minSpeed)) * (Math.round(Math.random()) ? 1 : -1)
    };
    let acc = {
      x: 1,
      y: 1
    };
    if (Math.random() < 0.5) {
      acc = {
        x: 0.25 + 0.75 * acc.x * Math.random(),
        y: 0.25 + 0.75 * acc.y * Math.random()
      };
    }
    particlePositions.push({ x, y, vel, acc });
  }
  return particlePositions;
}

function drawParticle(context, particle, particleSize, particleColor) {
  context.beginPath();
  context.globalAlpha = 1;
  context.arc(particle.x, particle.y, particleSize, 0, 2 * Math.PI);
  context.fillStyle = particleColor;
  context.fill();
}

function handleWallCollision(particle, width, height, minSpeed, maxSpeed, particleSize, triangleLeftPart, triangleRightPart, triangleHeight, alphaRight, alphaLeft) {
  if (particle.x < 0 + particleSize || particle.x > width - particleSize) {
    particle = {
      ...particle,
      vel: {
        x: -particle.vel.x,
        y: particle.vel.y
      },
      acc: {
        x: particle.acc.x,
        y: particle.acc.y
      }
    };
  }
  if (particle.y >= height - (width / 12.5) - particleSize * 2) {
    let allowedHeight;
    let velX;
    let velY;
    if (particle.x < triangleLeftPart) {
      velX = particle.vel.x < 0 ? -(minSpeed + maxSpeed) / 2 : (particle.vel.x / 2 < minSpeed ? minSpeed : particle.vel.x / 2);
      //tan(a) = opposite side / adjacent => tan(a) * adjacent = opposite side (blocking height)
      allowedHeight = alphaLeft * particle.x;
    } else {
      velX = particle.vel.x > 0 ? (minSpeed + maxSpeed) / 2 : (particle.vel.x / 2 < minSpeed ? -minSpeed : -particle.vel.x / 2);
      //tan(a) = opposite side / adjacent => tan(a) * adjacent = opposite side (blocking height)
      allowedHeight = alphaRight * (width - particle.x);
    }
    velY = particle.vel.y < 0 ? -(minSpeed + maxSpeed) / 2 : -particle.vel.y;
    if (particle.y >= height - allowedHeight - particleSize * 2) {
      particle = {
        ...particle,
        vel: {
          x: velX,
          y: velY
        },
        acc: {
          x: particle.acc.x,
          y: particle.acc.y
        }
      };
    }
  }
  if (particle.y < 0 + particleSize) {
    particle = {
      ...particle,
      vel: {
        x: particle.vel.x,
        y: -particle.vel.y
      },
      acc: {
        x: particle.acc.x,
        y: particle.acc.y
      }
    };
  }
  return particle;
}

function handleParticleCollision(particle) {
  return {
    ...particle,
    vel: {
      x: -particle.vel.x,
      y: -particle.vel.y
    },
    acc: {
      x: 0.25 + 0.75 * Math.random(),
      y: 0.25 + 0.75 * Math.random()
    }
  };

}

function calculateDistance(particleA, particleB) {
  let a = Math.pow((particleA.x - particleB.x), 2);
  let b = Math.pow((particleA.y - particleB.y), 2);
  return (a + b);
}

function drawLine(context, particleA, particleB, lineWidth, lineColor) {
  context.lineWidth = lineWidth;
  context.moveTo(particleA.x, particleA.y);
  context.lineTo(particleB.x, particleB.y);
  context.strokeStyle = lineColor;
  context.stroke();
}

function applyVelocityAndAcceleration(particle) {
  return {
    x: (particle.x + particle.vel.x * particle.acc.x),
    y: (particle.y + particle.vel.y * particle.acc.y),
    vel: particle.vel,
    acc: particle.acc
  };
}

const ParticleCanvas = (props) => {
  let canvas = useRef(null);
  let particlePositions = [];

  //variables for drawing particles
  const fps = 60;
  const particleCount = Math.floor(20 + props.width / 50);
  const particleSize = 3.5;
  const drawLineDistance = 150;
  const lineStrength = 0.25;
  const minSpeed = 0.2;
  const maxSpeed = 0.5;
  const particleColor = "#fafafa";
  const lineColor = "#fafafa";

  //bounce on diagonal on bottom
  const triangleLeftPart = props.width * 0.78;
  const triangleRightPart = props.width * 0.21;
  const triangleHeight = props.width / 12.5;
  const alphaRight = useMemo(() => Math.tan(triangleHeight / triangleRightPart), [triangleHeight, triangleRightPart]);
  const alphaLeft = useMemo(() => Math.tan(triangleHeight / triangleLeftPart), [triangleHeight, triangleLeftPart]);

  useEffect(() => {
    particlePositions = createParticles(particleCount, props.width, props.height, particleSize, minSpeed, maxSpeed);
  }, []);

  useEffect(() => {
    const context = canvas.current.getContext("2d")
    context.width = props.width;
    context.height = props.height;
    particlePositions = createParticles(particleCount, props.width, props.height, particleSize, minSpeed, maxSpeed);
  }, [props.width, props.height]);

  useEffect(() => {
    const context = canvas.current.getContext("2d");
    setInterval(() => {
      context.clearRect(0, 0, props.width, props.height);
      for (let i = 0; i < particleCount; i++) {
        drawParticle(context, particlePositions[i], particleSize, particleColor);
        particlePositions[i] = handleWallCollision(particlePositions[i], props.width, props.height, minSpeed, maxSpeed, particleSize, triangleLeftPart, triangleRightPart, triangleHeight, alphaRight, alphaLeft);
        for (let j = 0; j < particleCount; j++) {
          //skip self
          if (particlePositions[i] === particlePositions[j]) {
            continue;
          }
          let distance = calculateDistance(particlePositions[i], particlePositions[j]);
          if (distance < drawLineDistance * drawLineDistance && distance > particleSize * particleSize) {
            const distanceOfDrawLineDistanceAsPercent = 1 - (distance / 100) / (drawLineDistance * drawLineDistance / 100);
            drawLine(context, particlePositions[i], particlePositions[j], lineStrength * distanceOfDrawLineDistanceAsPercent, lineColor);
          }
          if (distance < particleSize) {
            particlePositions[i] = handleParticleCollision(particlePositions[i]);
            particlePositions[j] = handleParticleCollision(particlePositions[j]);
          }
        }
        particlePositions[i] = applyVelocityAndAcceleration(particlePositions[i]);
      }
    }, (1000 / fps));
  }, [canvas]);

  return (
    <canvas ref={canvas} width={props.width} height={props.height}
            className={"absolute top-0 left-0 w-full h-full mt-0 md:pb-[90px] md:mt-[90px]"}></canvas>
  );
};
export default ParticleCanvas;