import React from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import config from "../../config/main.config";

class NotFoundPage extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { t } = this.props;

    return (
      <div className={"pt-[100px] small:pt-[50px] sm:pt-[120px]"}>
        <Helmet>
          <title>HTTP 404 - Seite nicht gefunden</title>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, minimum-scale=1, maximum-scale=1.0"
          />
          <meta
            name="description"
            content="www.aaa-soft.net - Wir setzten durch agile und innovative Entwicklung Ihre Webapplikation mit den neusten Technologien um – Ihr Mehrwert von Anfang an!"
          />
          <meta name="author" content="Triple-A Soft UG" />
          <meta name="revisit-after" content="30 days" />
          <meta name="expires" content="NEVER" />
          <meta name="publisher" content="Triple-A Soft UG" />
          <meta name="copyright" content="Triple-A Soft UG" />
          <meta name="content-language" content="de" />
          <meta name="language" content="deutsch, de" />
          <meta name="audience" content="Alle" />
          <meta name="robots" content="noindex,follow" />
        </Helmet>
        <div className={"grid grid-cols-9 min-h-[58.5vh]"}>
          <div className={"row-start-1 col-start-4 col-span-3 sm:col-start-5 sm:col-span-1"}>
            <img className="social_icon w-full h-full" loading="lazy" alt="notFound" src={config.PUBLIC_URL + 'images/icons/pleadingFace.svg'}/>
          </div>
          <div className={"row-start-2 col-start-3 col-span-5 "}>
            <h2 className={"subheader-text"}>{t("404.title")}</h2>
            <p className={"blue-text"}>{t("404.text")}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(NotFoundPage);
