import React, { Component } from 'react';
import config from '../../config/main.config';
import { withTranslation } from 'react-i18next';
import { regexpMail } from '../../helper/util';

class NewsLetterSubscribeComponent extends Component {
  state = {
    loading: false,
    email: '',
    emailError: false,
    success: false
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.sendNewsletterSubscription = this.sendNewsletterSubscription.bind(
      this
    );
  }

  handleChange(prop, e) {
    this.setState({
      ...this.state,
      [prop]: e.target.value
    });
  }

  sendNewsletterSubscription() {
    let hadError = false;
    let { email } = this.state;

    if (email.length === 0 || !email.match(regexpMail)) {
      this.setState({ ...this.state, emailError: true });
      hadError = true;
    } else {
      this.setState({ ...this.state, emailError: false });
    }

    if (!hadError) {
      this.setState({ ...this.state, loading: true });
      let self = this;

      fetch(config.BASE_URL + 'newsletter', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          email: email
        })
      })
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            self.setState({
              ...this.state,
              email: '',
              loading: false,
              success: true
            });

            setTimeout(() => {
              self.setState({ ...this.state, success: false });
            }, 500);
          } else {
            self.setState({
              ...this.state,
              emailError: true,
              loading: false
            });
          }
        })
        .catch(_ => {
          self.setState({
            ...this.state,
            emailError: true,
            loading: false
          });
        });
    }
  }

  render() {
    const { t } = this.props;
    let { loading, emailError, success } = this.state;

    return (
      <div className={"pl-[14px], pr-[14px] overflow-hidden"}>
        <div className="block">
          <label className={'footer_headers'} htmlFor={'newsletterComp'}>
            {t('newsletter.title')}
          </label>
          <div className={(loading ? 'loading' : '') + (emailError ? 'error' : '') + 'block'}>
            <input
              id={'newsletterComp'}
              className={'newsletter_text_box !w-full'}
              type="text"
              value={this.state.email}
              placeholder={t('newsletter.email')}
              onChange={this.handleChange.bind(this, 'email')}
            /><br/>
            <button
              className={'subscribe_button w-full'}
              onClick={this.sendNewsletterSubscription}
            >
              {success ? (
                <i
                  aria-hidden="true"
                  className={'check icon text-white'}
                />
              ) : (
                t('newsletter.send_button').toUpperCase()
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(NewsLetterSubscribeComponent);
