import React from "react";
import config from "../../config/main.config";
import { withTranslation } from "react-i18next";
import NewsLetterSubscribeComponent from "../newsletter/NewsLetterSubscribeComponent";
import { NavLink } from "react-router-dom";

const FooterComponent = props => {
  const { t } = props;
  let copyright = ` © ${new Date().getFullYear()} Triple-A soft UG (haftungsbeschränkt)`;

  return (
    <div className={"grid grid-cols-3 big:grid-cols-6"}>
      <div className={"col-start-1 row-start-1 col-span-6 w-fit"}>
        <NavLink to={"/"}>
          <img
            loading={"lazy"}
            className={"ui image footer-logo"}
            alt={"triple a soft logo"}
            src={config.PUBLIC_URL + "images/company_logos/logo.svg"}
          />
        </NavLink>
      </div>
      <div className={"col-start-1 md:col-start-1 big:col-start-2 md:row-start-2 row-start-2 col-span-6 md:col-span-1 m-4"}>
        <NavLink to={"/imprint"} className={"footer_link"}>
          {t("titles.imprint")}<br/>
        </NavLink>
        <NavLink to={"/data-privacy"} className={"footer_link"}>
          {t("titles.data_privacy")}<br/>
        </NavLink>
      </div>
      <div className={"col-start-1 md:col-start-2 big:col-start-3 md:row-start-2 row-start-3 col-span-6 md:col-span-1 m-4"}>
        <NavLink to={"/services"} className={"footer_link"}>
          {t("titles.services")}<br/>
        </NavLink>
        <NavLink to={"/about"} className={"footer_link"}>
          {t("titles.about")}<br/>
        </NavLink>
        <NavLink to={"/faq"} className={"footer_link"}>
          {t("titles.faq")}<br/>
        </NavLink>
      </div>
      <div className={"col-start-1 md:col-start-3 big:col-start-4 md:row-start-2 row-start-4 col-span-6 md:col-span-1 m-4 msw:!mr-0"}>
        <p className={"footer_headers"}>{t("titles.contact")}</p>
        <p className={"footer_text overflow-auto"}>
          Danzigerstraße 3 <br />
          88250 Weingarten
          <br />
          Tel.: +49 (0) 751 951 264 03
          <br />
          <a
            href={"mailto:kontakt@aaa-soft.net?subject=Kontakt_Webseite"}
            className={"contact_mail"}
          >
            kontakt@aaa-soft.net
          </a>
        </p>
      </div>
      <div className={"col-start-1 md:col-start-1 big:col-start-5 md:row-start-3 row-start-5 col-span-3 big:row-start-2 md:col-span-1 m-4"}>
        <NewsLetterSubscribeComponent />
      </div>
      <div className={"col-start-1 md:col-start-2 big:col-start-6 md:row-start-3 row-start-6 col-span-6 big:row-start-2 md:col-span-1 big:col-span-2 self-center"}>
        <div className={"flex flex-row justify-center max-md:mr-[4rem]"}>
          <div className={"min-w-[50px] max-w-[50px] max-md:m-4"}>
            <a className={""}
              href={"https://www.linkedin.com/company/77616464"}
              target={"_blank"}
              rel="noreferrer"
              aria-label="LinkedIn"
            >
              <img className="social_icon w-full h-full" loading="lazy" alt="linkedin" src={config.PUBLIC_URL + 'images/icons/linkedin.svg'}/>
            </a>
          </div>
          <div className={"min-w-[50px] max-w-[50px] max-md:m-4"}>
            <a className={""}
              href={"https://www.xing.com/pages/triple-a-soft-ug-haftungsbeschraenkt"}
              target={"_blank"}
              rel="noreferrer"
              aria-label="XING"
            >
              <img className="social_icon w-full h-full" loading="lazy" alt="xing" src={config.PUBLIC_URL + 'images/icons/xing.svg'}/>
            </a>
          </div>
          <div className={"min-w-[50px] max-w-[50px] max-md:m-4"}>
            <a className={""}
              href={"https://www.facebook.com/triple.a.soft"}
              target={"_blank"}
              rel="noreferrer"
              aria-label="Facebook"
            >
              <img className="social_icon w-full h-full" loading="lazy" alt="facebook" src={config.PUBLIC_URL + 'images/icons/facebook.svg'}/>
            </a>
          </div>
          <div className={"min-w-[50px] max-w-[50px] max-md:m-4 "}>
            <a className={""}
              href={"https://www.instagram.com/triple.a.soft/"}
              target={"_blank"}
              rel="noreferrer"
              aria-label="Instagram"
            >
              <img className="social_icon w-full h-full" loading="lazy" alt="instagram" src={config.PUBLIC_URL + 'images/icons/instagram.svg'}/>
            </a>
          </div>
        </div>
      </div>
      <div className={"col-start-1 col-span-6 msw:row-start-4 m-4 bg-primary text-white m-0 min-w-[100%]"}>
        <p className={"ml-[15px] font-semi-bold"}>{copyright}</p>
      </div>
    </div>
  );
};
export default withTranslation()(FooterComponent);
