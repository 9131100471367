import React from "react";
import { Helmet } from "react-helmet";
import ParticleLine from "../particle_line/ParticleLine";
import { withTranslation } from "react-i18next";
import Card from "../cards/Card";
import CircleProgressBar from "../circle/CircleProgressBar";
import { NavLink } from "react-router-dom";
import BreadCrumbs from "../breadcrumbs/BreadCrumbs";

class ServicesPage extends React.Component {
  state = {
    applicationWith: 1200,
    percentage: 0
  };

  constructor(props) {
    super(props);
    this.percentRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.updateDimensions);
      window.addEventListener("scroll", this.listenToScroll);
    }
    this.updateDimensions();
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateDimensions);
      window.removeEventListener("scroll", this.listenToScroll);
    }
  }

  updateDimensions = () => {
    this.setState({
      applicationWith: window.outerWidth
    });
  };

  listenToScroll = () => {
    if (
      document.documentElement.clientHeight >=
      this.percentRef.current.getBoundingClientRect().top
    ) {
      this.setState({
        ...this.state,
        percentage: 100
      });
      window.removeEventListener("scroll", this.listenToScroll);
    }
  };

  render() {
    const { applicationWith } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className={"hidden"}>
          <BreadCrumbs
            breadCrumbData={[{ title: "Leistungen", url: "/services" }]}
          />
        </div>
        <Helmet>
          <title>LEISTUNGEN | Triple-A Soft</title>
          <meta name="description" content={"Webentwicklung und Beratung."} />
          <meta name="robots" content="index,follow" />
          <meta name="title" content="Leistungen" />

          <meta
            property="og:site_name"
            content="Triple-A Soft UG (Haftungsbeschränkt)"
          />
          <meta property="og:title" content="Leistungen" />
          <meta
            property="og:description"
            content={"Webentwicklung und Beratung."}
          />
          <meta property="og:url" content="https://www.aaa-soft.net" />
          <meta
            property="og:image:url"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content="Leistungen" />
          <meta
            name="twitter:description"
            content="Webentwicklung und Beratung."
          />
          <meta
            name="twitter:image"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <ParticleLine>
          <h1>{t("header.our_service")}</h1>
          <p className={"page-header"}>{t("header.service")}</p>
        </ParticleLine>
        <div className={"grid grid-cols-15"}>
          <div className={"row-start-1 col-start-3 col-span-11 msw:col-start-3 msw:col-span-7"}>
            <h2>{t("header.web_for_you")}</h2>
            <p className={"text-2"}>
              Wir sind spezialisiert auf die
              Gestaltung und Programmierung von Webanwendungen und
              Portalen. Vom Entwurf, bis zur Umsetzung und der Einführung sind
              wir Ihr Ansprechpartner.
            </p>
          </div>
          <div className={"row-start-2 col-start-3 col-span-11"}>
            <div className={"flex flex-wrap flex-row justify-start"}>
              <div className={"min-w-[290px] w-[290px] m-2"}>
                <Card
                  cardImageLink={""}
                  cardHeader={`${t("card.workshop")}`}
                  cardImageSrc={"images/cards/Triple-A_Workshop.svg"}
                />
              </div>
              <div className={"min-w-[290px] w-[290px] m-2"}>
                <Card className={"row-start-2 col-start-3"}
                      cardHeader={`${t("card.project")}`}
                      cardImageSrc={"images/cards/Triple-A_Vorprojekt.svg"}
                      cardImageLink={""}
                />
              </div>
              <div className={"min-w-[290px] w-[290px] m-2"}>
                <Card className={"row-start-2 col-start-4"}
                      cardHeader={`${t("card.develop")}`}
                      cardImageSrc={"images/cards/Triple-A_Entwicklung.svg"}
                      cardImageLink={""}
                />
              </div>
              <div className={"min-w-[290px] w-[290px] m-2"}>
                <Card
                  cardHeader={`${t("card.optimization")}`}
                  cardImageSrc={"images/cards/Triple-A_Optimierung.svg"}
                  cardImageLink={""}
                />
              </div>
              <div className={"min-w-[290px] w-[290px] m-2"}>
                <Card
                  cardHeader={[
                    `${t("card.introduction")}`,
                    <br />,
                    `${t("card.support")}`,
                    <br />,
                    `${t("card.education")}`
                  ]}
                  cardImageSrc={"images/cards/Triple-A_Schulung.svg"}
                  cardImageLink={""}
                />
              </div>
            </div>
          </div>
          <div className={"row-start-3 col-start-3 col-span-11 msw:col-start-3 msw:col-span-7"}>
            <h4>{t("header.step_by_step")}</h4>
            <p className={"text-2"}>
              Wir begreifen Digitalisierung als Chance und gestalten diese aktiv mit. Unser Ziel ist es,
              Ihr Unternehmen mit innovativen digitalen Ideen auf das nächste Level zu bringen. Durch
              digitalisierte Prozesse kann die einzigartige menschliche Kreativität wieder da
              eingesetzt werden, wo sie wirklich benötigt wird. Digitalisierung ist eine globale
              Entwicklung, die wir mit Ihnen gestalten und vorantreiben wollen!
            </p>
            <p className={"text-2"}>
              <b>{t("card.workshop")}.</b> Unser Innovationsworkshop ist der persönlichen Besuch in Ihrem
              Hause. Dort nehmen wir zusammen Ihre aktuellen Herausforderungen unter die Lupe,
              und schlagen ihnen auf Ihre Wünsche und Bedürfnisse angepasste aktuelle Technologien
              und Trends vor. Hierbei ist es uns wichtig, gemeinsam Lösungen und ein neues Konzept
              für Ihr Unternehmen zu entwickeln.
            </p>
            <p className={"text-2"}>
              <b>{t("card.project")}.</b> Nach dem Innovationsworkshop bieten wir Ihnen ein Konzept an. Hier
              visualisieren, wie Ihre Lösung aussehen könnte. Wir evaluieren, welche Technologien
              sinnvoll sind und schätzen Ihr Projekt präzise ab.
            </p>
            <p className={"text-2"}>
              <b>{t("card.develop")}.</b> Im agilen Entwicklungsprozess erarbeiten wir die Lösung. Wir binden
              frühzeitig und regelmäßig die betroffenen Nutzer und Bereiche im Unternehmen ins
              Projekt ein. Dadurch kommt das Projekt effizienter voran.
            </p>
            <p className={"text-2"}>
              <b>{t("card.optimization")}.</b> Vor der Veröffentlichung geben
              wir der Software noch den letzten Schliff. Hier wird die
              Performance der Applikation und die Struktur nochmals überprüft
              und angepasst. Dabei untersuchen wir die Bedienbarkeit der
              Webanwendung auf allen Bildschirmgrößen. Zudem werden
              Verknüpfungen und Texte für Suchmaschinen optimiert, damit Ihre
              Kunden Sie besser finden können.
            </p>
          </div>
          <div className={"row-start-4 col-start-3 col-span-11 msw:col-start-3 msw:col-span-7"}>
            <div className={"flex flex-wrap justify-center"}>
              <div className={"flex flex-row w-fit justify-center"}>
                <div ref={this.percentRef} />
                <div className={"w-[150px] max-w-[250px] justify-self-center"}>
                  <p className={"text-2 text-center"}>
                    <CircleProgressBar percentage={this.state.percentage} />
                    Performance
                  </p>
                </div>
                <div className={"w-[150px] max-w-[250px] justify-self-center"}>
                  <p className={"text-2 text-center"}>
                    <CircleProgressBar percentage={this.state.percentage} />
                    Accessibility
                  </p>
                </div>
              </div>
              <div className={"flex flex-row w-fit justify-center"}>
                <div className={"w-[150px] max-w-[250px] justify-self-center"}>
                  <p className={"text-2 text-center"}>
                    <CircleProgressBar percentage={this.state.percentage} />
                    Best Practice
                  </p>
                </div>
                <div className={"w-[150px] max-w-[250px] justify-self-center"}>
                  <p className={"text-2 text-center"}>
                    <CircleProgressBar percentage={this.state.percentage} />
                    SEO
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={"row-start-5 col-start-3 col-span-11 msw:col-start-3 msw:col-span-7"}>
            <p className={"text-2"}>
              <b>Einführung, Support und Schulung.</b> Neue Technologien erlauben neue Möglichkeiten. Bei
              der Arbeit mit neuen Werkzeugen fallen den Nutzern oder uns neue Funktionen ein, die
              integriert werden könnten. Dies ist die Phase nach der ersten Einführung, in der wir als
              Team Sie mit dem neuen Produkt nicht alleine lassen.
            </p>
            <NavLink to={"/configurator"} exact>
              <button className={"button-1 w-fit"}>
                {t("callToAction.getOffer").toUpperCase()}
              </button>
            </NavLink>
          </div>
          <div className={"row-start-6 col-start-3 col-span-11 msw:col-start-3 msw:col-span-7 mt-[40px]"}>
            <h2 className={"mt-[20px]"}>{t("header.security")}</h2>
            <p className={"text-2"}>
              Was ist schützenswert und wie ist der aktuelle Zustand Ihrer IT? Wir überprüfen ihr
              aktuelles System und entwickelt mit Ihnen ein Sicherheitskonzept für Ihr Unternehmen.
            </p>
          </div>
          <div className={"row-start-7 col-start-3 col-span-11 flex flex-wrap flex-row"}>
            <div className={"min-w-[290px] w-[290px] m-2"}>
              <Card
                cardHeader={"Analyse"}
                cardImageSrc={"images/cards/Triple-A_Analyse.svg"}
                cardImageLink={""}
              />
            </div>
            <div className={"min-w-[290px] w-[290px] m-2"}>
              <Card
                cardHeader={"Beratung"}
                cardImageSrc={"images/cards/Triple-A_Beratung.svg"}
                cardImageLink={""}
              />
            </div>
            <div className={"min-w-[290px] w-[290px] m-2"}>
              <Card
                cardHeader={"Lösung"}
                cardImageSrc={"images/cards/Triple-A_Loesung.svg"}
                cardImageLink={""}
              />
            </div>
          </div>
          <div className={"row-start-8 col-start-3 col-span-11 msw:col-start-3 msw:col-span-7"}>
            <h4>{t("header.strategy")}.</h4>
            <p className={"text-2"}>
              IT-Umgebungen werden täglich komplexer. die Auswahl der richtigen
              Vorsichtsmaßnahmen ist nicht ganz einfach. Eine gute Daten und Netzwerksicherheit
              vermindert die Anzahl der Schwachstellen, eliminiert die verbleibenden Risiken und
              grenzt potentielle Schäden für das Unternehmen ein.
            </p>
            <p className={"text-2"}>
              Es bedarf mehr denn je das Zusammenspiel aus Menschen, Prozessen und Technologie.
              Wir erarbeiten zusammen mit Ihnen einen strategischen Masterplan, der Ihnen
              Mehrwerte wie Transparenz, Nachhaltigkeit und Reaktionsgeschwindigkeit bietet.
            </p>
            <p className={"text-2"}>
              Unser Service bietet Ihnen unter anderem umfangreiche Netzwerkanalysen, Beratung im
              Sicherheitsvorgehen, und Entwicklung in eines angemessenen Digitalisierungs Modells.
              Mit uns gehen Sie auf Nummer sicher!
            </p>
            <NavLink to={"/contact"}>
              <button className={"button-1 w-fit"}>
                {t("services.request").toUpperCase()}
              </button>
            </NavLink>
          </div>
          <div className={"row-start-9 col-start-1 col-span-15 h-fit pt-0 pb-0 bg-secondary_dark_color"}>
            <svg viewBox={"0 0 100 10"}>
              <polygon points={`0,5 0,0 100,0`} style={{ fill: "#FAFAFA" }} />
            </svg>
            <div className={"grid grid-cols-15"}>
              <div className={"row-start-1 col-start-3 col-span-11 flex flex-col"}>
                <div className={"max-w-[625px] msw:m-0"}>
                  <p className={"quotation-banner-text-white pt-[25px pb-[25px]"}>
                    {t("services.quote_1")}
                    <p className={"text-2-white"}>
                      <svg className={"mr-[10px] inline"} width={50} height={5}>
                        <line
                          x1={0}
                          y1={0}
                          x2={45}
                          y2={0}
                          strokeWidth={"10px"}
                          stroke={"#EF9613"}
                        />
                      </svg>
                      Arno Uttenweiler, Geschäftsführer AS-GmbH
                    </p>
                  </p>
                </div>
                <div className={"max-w-[625px] m-0 md:ml-[4rem] msw:m-0 self-end"}>
                  <p className={"quotation-banner-text-white pt-[25px] pb-[25px]"}>
                    {t("services.quote_3")}
                    <p className={"text-2-white pb-[4rem]"}>
                      Regina Kuhn, Leitung Musikschule Lindau
                      <svg className={"ml-[10px] inline"} width={50} height={5}>
                        <line
                          x1={0}
                          y1={0}
                          x2={45}
                          y2={0}
                          strokeWidth={"10px"}
                          stroke={"#EF9613"}
                        />
                      </svg>
                    </p>
                  </p>
                </div>
                <div className={"max-w-[625px] m-0 md:ml-[8rem] msw:m-0"}>
                  <p className={"quotation-banner-text-white pt-[25px pb-[25px]"}>
                    {t("services.quote_2")}
                    <p className={"text-2-white"}>
                      <svg className={"mr-[10px] inline"} width={50} height={5}>
                        <line
                          x1={0}
                          y1={0}
                          x2={45}
                          y2={0}
                          strokeWidth={"10px"}
                          stroke={"#EF9613"}
                        />
                      </svg>
                      Joachim Silbereis, Selbständig
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <svg className={"mb-[-1px]"} viewBox={"0 0 100 10"}>
              <polygon points={`0,10 0,5 100,10`} style={{ fill: "#FAFAFA" }} />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ServicesPage);
