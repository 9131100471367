import React, { useEffect, useState } from "react";
import config from "../../config/main.config";
import { Helmet } from "react-helmet";
import { regexpMail } from "../../helper/util";
import ParticleLine from "../particle_line/ParticleLine";
import { NavLink } from "react-router-dom";
import fetch from "isomorphic-unfetch";

import BreadCrumbs from "../breadcrumbs/BreadCrumbs";
import { withTranslation } from "react-i18next";
import MapContainer from "../map/Map";

function submitMessage(
  email,
  setEmailError,
  conditionsChecked,
  setConditionsCheckedError,
  name,
  company,
  address,
  message,
  newsletterCheck,
  setModalMessage,
  setModalOpen
) {
  let canSend = true;

  if (email.match(regexpMail) && email.length > 0) {
    setEmailError(false);
  } else {
    setEmailError(true);
    canSend = false;
  }

  if (conditionsChecked) {
    setConditionsCheckedError(false);
  } else {
    setConditionsCheckedError(true);
    canSend = false;
  }
  if (canSend) {
    //Send to server
    let data = {
      name: name,
      subject: "",
      email: email,
      company: company,
      address: address,
      text: message,
      newsletter: newsletterCheck
    };
    fetch(config.BASE_URL + "contact", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(function(response) {
        if (response.status >= 200 && response.status < 300) {
          response.json().then(json => {
            if (json.hasOwnProperty("success") && json) {
              setModalMessage(`Vielen Dank! \n\n Ihre Nachricht wird bearbeitet ☺`);
            } else {
              setModalMessage("Beim senden Ihrer Nachricht ist ein Fehler unterlaufen (>﹏<)");
            }
            setModalOpen(true);
          });
        } else {
          setModalMessage("Beim senden Ihrer Nachricht ist ein Fehler unterlaufen (>﹏<)");
          setModalOpen(true);
        }
      })
      .catch(() => {
        setModalMessage("Beim senden Ihrer Nachricht ist ein Fehler unterlaufen (>﹏<)");
        setModalOpen(true);
      });
  }
}

const Contact = props => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [conditionsChecked, setConditionsChecked] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [conditionsCheckedError, setConditionsCheckedError] = useState(false);
  const [newsletterCheck, setNewsletterCheck] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { t } = props;

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div>
      <div className={"hidden"}>
        <BreadCrumbs breadCrumbData={[{ title: "Kontakt", url: "/contact" }]} />
      </div>
      <Helmet>
        <title>KONTAKT | Triple-A Soft</title>
        <meta
          name="description"
          content="Kontaktieren Sie uns für ein unverblindliches Gespräch."
        />
        <meta name="robots" content="index,follow" />
        <meta
          property="og:site_name"
          content="Triple-A Soft UG (Haftungsbeschränkt)"
        />
        <meta property="og:title" content="Kontakt" />
        <meta name="title" content="Kontakt" />
        <meta
          property="og:description"
          content="Kontaktieren Sie uns für ein unverblindliches Gespräch."
        />
        <meta property="og:url" content="https://www.aaa-soft.net" />
        <meta
          property="og:image:url"
          content="https://www.aaa-soft.net/images/image.png"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <ParticleLine>
        <h1>Kontakt</h1>
        <p className={"page-header mb-0"}>
          Unverbindliche Anfrage
        </p>
      </ParticleLine>
      {modalOpen ?
        <div className={"grid bg-light_text"}>
          <h2 className={"text-center mt-[8rem] mb-[4rem] whitespace-pre-line leading-[5rem]"}>
          {modalMessage}
          </h2>
          <NavLink to={"/"} className={"button-1 m-auto w-fit mb-[8rem] flex items-center"}>Zurück auf die Home seite</NavLink>
        </div>
        : <div className={"grid grid-cols-12 md:grid-cols-8 fit bg-light_text"}>
        <div className={"row-start-1 col-start-2 col-span-10 md:row-start-1 md:col-start-2 md:col-span-5"}>
          <p className={"text-2 mb-8"}>
            Mit einem * markierte Felder sind Pflichtfelder.
          </p>
        </div>
        <div className={"row-start-2 col-start-2 col-span-10 md:row-start-2 md:col-start-2 md:col-span-3"}>
          <input
            className={"text-box-white"}
            type="text"
            value={name}
            placeholder={"Name"}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className={"row-start-3 col-start-2 col-span-10 md:row-start-2 md:col-start-5 md:col-span-3"}>
          <input
            className={"text-box-white"}
            type="text"
            value={company}
            placeholder={"Firma"}
            onChange={e => setCompany(e.target.value)}
          />
        </div>
        <div className={"row-start-4 col-start-2 col-span-10 md:row-start-3 md:col-start-2 md:col-span-3"}>
          <input
            className={"text-box-white"}
            type="text"
            value={address}
            placeholder={"Adresse"}
            onChange={e => setAddress(e.target.value)}
          />
        </div>
        <div className={"row-start-5 col-start-2 col-span-10 md:row-start-3 md:col-start-5 md:col-span-3"}>
          <input
            className={emailError ? "error-input text-box-white" : "text-box-white"}
            type="text"
            value={email}
            onClick={() => setEmailError(false)}
            placeholder={"* E-Mail"}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className={"row-start-6 col-start-2 col-span-10 md:row-start-4 md:col-start-2 md:col-span-6"}>
          <textarea
            className={"text-box-white min-h-[250px] mb-0"}
            value={message}
            placeholder={"Nachricht"}
            onChange={e => setMessage(e.target.value)}
          />
        </div>
        <div className={"row-start-7 col-start-2 col-span-10 md:row-start-5 md:col-start-2 md:col-span-6"}>
          <div className={"grid grid-cols-8"}>
            <div className={"col-span-8 row-start-1 col-start-1 md:col-span-6"}>
              <div className={conditionsCheckedError ? "error-input-text field accept-terms" : "field accept-terms"}>
                <div className="checkbox-contact">
                  <label className={"ml-2"}>
                    <input
                      className={"w-[17px] h-[17px] mr-2 cursor-pointer"}
                      tabIndex="0"
                      type="checkbox"
                      onChange={e => {
                        setConditionsCheckedError(false);
                        setConditionsChecked(e.target.checked);
                      }}
                      value={conditionsChecked}
                    />
                    {"* " + t("contact_form.terms_conditions")}
                  </label>
                  <NavLink exact to="/data-privacy" className={"accept-terms-link underline"}>
                    {t("contact_form.privacy_link_text")}
                  </NavLink>
                </div>
              </div>
            </div>
            <div className={"col-span-8 row-start-2 col-start-1 md:col-span-6 field"}>
              <div className="checkbox-contact">
                <label className={"ml-2"}>
                  <input
                    className={"w-[17px] h-[17px] mr-2 cursor-pointer"}
                    type="checkbox"
                    onChange={e => setNewsletterCheck(e.target.checked)}
                    value={newsletterCheck}
                  />
                  {t("contact_form.newsletter")}
                </label>
              </div>
            </div>
            <div className={"col-span-8 row-start-3 col-start-1 mb-4 md:col-span-3"}>
              <button
                type="submit"
                className={"button-1 w-fit"}
                onClick={() =>
                  submitMessage(
                    email,
                    setEmailError,
                    conditionsChecked,
                    setConditionsCheckedError,
                    name,
                    company,
                    address,
                    message,
                    newsletterCheck,
                    setModalMessage,
                    setModalOpen
                  )
                }
              >
                {t("contact_form.submit").toUpperCase()}
              </button>
            </div>
          </div>
        </div>
        <div className={"row-start-8 col-start-1 col-span-12 md:col-span-8 grid grid-cols-12 mb-4 banner_container"}>
          <div className={"row-start-1 col-start-2 col-span-4 banner_text"}>
            <h2 className={"text-white"}>Hier finden Sie uns.</h2>
            <p className={"text-2-white"}>
              Cubus Competence Center <br />
              Danziger Straße 3<br />
              88250 Weingarten
            </p>
          </div>
          <div className={"row-start-1 col-start-6 col-span-7"}>
            <div className={"contact_map_container"}>
              <MapContainer />
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default withTranslation()(Contact);
