import React from "react";
import { withTranslation } from "react-i18next";
import BreadCrumbs from "../breadcrumbs/BreadCrumbs";
import ParticleLine from "../particle_line/ParticleLine";
import { NavLink } from "react-router-dom";

class FaqPage extends React.Component {
  constructor(props) {
    super(props);
    if (
      typeof props.staticContext !== "undefined" &&
      props.staticContext.length > 0
    ) {
      this.state = {
        activeIndexes: [],
        allFAQs: props.staticContext
      };
    } else {
      this.state = {
        activeIndexes: [],
        allFAQs: [
          {
            title: "Wieso sollte man etwas digitalisieren?",
            text:
              "Durch gezielte Digitalisierung wird ein Medienbruch vermieden, und ein flüssiger Arbeitsprozess " +
              "ermöglicht. Somit stehen allen Beteiligten die Daten in genau der Form zur Verfügung, die zur optimalen " +
              "Ausführung des Tagesgeschäfts benötigt werden. So können Kosten vermieden und Prozesse beschleunigt " +
              "werden."
          },
          {
            title: "Wie lange dauert es eine Webapp zu entwickeln?",
            text:
              "Projektzeiten für digitale Produkte sind sehr variabel. Bei einer einfachen Website dauert der Prozess von " +
              "Entwurf, Entwicklung bis zur Live-Schaltung 1 bis 3 Monate. Bei größeren Vorhaben kann es bis zu " +
              "mehreren Jahren gehen, bis das Konzept im vollen Umfang umgesetzt ist."
          },
          {
            title: "Welchen Vorteil bringt eine Cloud-Anwendung?",
            text:
              "Cloud Computing bietet neben Mobilität auch einfache Skalierbarkeit, Aktualität und Datensicherheit. Durch " +
              "die Nutzung von Cloud Server muss keine eigene Infrastruktur vor Ort aufgestellt werden. Alle " +
              "notwendigen Ressourcen stellt der Cloud Anbieter bereit. Kurzfristige Anpassungen auf " +
              "Bedarfsänderungen sind somit jederzeit möglich."
          },
          {
            title: "Was ist eine Webanwendung?",
            text:
              "Eine Webanwendung (Webapplikation oder Web-App) ist ein Anwendungsprogramm, das auf einem " +
              "entfernten Server gespeichert und im Web über eine Browser-Schnittstelle bereitgestellt wird. So " +
              "verhindert man, dass auf jedem Rechner ein Programm installiert und manuell „up to date“ gehalten " +
              "werden muss."
          }
        ]
      };
    }
  }

  handleClick = index => {
    let self = this.state;
    if (self.activeIndexes.includes(index)) {
      self.activeIndexes[index] = null;
    } else {
      self.activeIndexes[index] = index;
    }
    this.setState({ ...this.state });
  };

  makeNewFaq(faqs) {
    return faqs.map((faq, index) => (
      <div className={`row-start-[${index + 1}] col-start-3 col-span-11 mt-8 mb-8`}
           itemScope
           itemProp="mainEntity"
           itemType="https://schema.org/Question"
           key={"faq" + index}
      >
        <h5 itemProp={"name"} className={"faq_title mb-4"} key={index}>
          {faq.title}
        </h5>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
          className={"content active"}
        >
          <p itemProp="text" className={"faq_text"}>
            {faq.text}
          </p>
        </div>
      </div>
    ));
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className={"hidden"}>
          <BreadCrumbs breadCrumbData={[{ title: "FAQ", url: "/faq" }]} />
        </div>
        <ParticleLine>
          <h1>Fragen und Antworten</h1>
          <p className={"page-header mb-0"}>
            Die am häufigsten gestellten Fragen
          </p>
        </ParticleLine>
        <div>
          {this.state.allFAQs.length !== 0 ? (
            <div className={"grid grid-cols-15 bg-light_text"}
                 itemScope
                 itemType="https://schema.org/FAQPage"
            >
              {this.makeNewFaq(this.state.allFAQs)}
            </div>
          ) : null}
          <div className={"grid grid-cols-15 mt-8 mb-8"}>
            <div className={`row-start-1 col-start-3 col-span-11`}>
              <h5 className={"faq_title mb-4"}>Haben Sie weitere Fragen?</h5>
              <p className={"faq_text mb-8"} itemProp="text">
                Wir helfen Ihnen gerne weiter.
              </p>
              <NavLink to={"/contact"} exact>
                <button className={"button-2"}>
                  {t("titles.contact").toUpperCase()}
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FaqPage);
