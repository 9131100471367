import React from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import ParticleLine from "../particle_line/ParticleLine";
import config from "../../config/main.config";
import BreadCrumbs from "../breadcrumbs/BreadCrumbs";

class EngagementPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationWidth: 700
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.updateDimensions);
    }
    this.updateDimensions();
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateDimensions);
    }
  }

  updateDimensions = () => {
    this.setState({
      ...this.state,
      applicationWidth: window.outerWidth
    });
  };

  render() {
    return (
      <div>
        <div className={"hidden"}>
          <BreadCrumbs
            breadCrumbData={[{ title: "Engagement", url: "/engagement" }]}
          />
        </div>
        <Helmet>
          <title>ENGAGEMENT | Triple-A Soft</title>
          <meta
            name="description"
            content={
              "Wir setzten durch agile und innovative Entwicklung Ihre Webapplikation mit den neusten Technologien um – Ihr Mehrwert von Anfang an!"
            }
          />
          <meta name="robots" content="index,follow" />
          <meta
            name="title"
            content="Triple-A Soft UG | Webentwicklung im Schussental"
          />
          <meta
            property="og:site_name"
            content="Triple-A Soft UG (Haftungsbeschränkt)"
          />
          <meta
            property="og:title"
            content="Triple A Soft UG | Webentwicklung im Schussental"
          />
          <meta
            property="og:description"
            content="Wir setzten durch agile und innovative Entwicklung dienende Digitalisierung mit den neusten Technologien um."
          />

          <meta property="og:url" content="https://www.aaa-soft.net" />
          <meta
            property="og:image"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta property="og:type" content="website" />

          <meta
            name="twitter:title"
            content="Triple A Soft UG | Webentwicklung im Schussental"
          />
          <meta
            name="twitter:description"
            content="Wir setzten durch agile und innovative Entwicklung dienende Digitalisierung mit den neusten Technologien um."
          />
          <meta
            name="twitter:image"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <ParticleLine>
          <h1>User Engagement</h1>
          <p className={"page-header"}>Unser Engagement</p>
        </ParticleLine>
        <div className={"grid grid-cols-1 msw:grid-cols-6"}>
          <div className={"row-start-1 col-start-1 msw:row-start-1 xl:col-start-2 xl:col-span-2 msw:col-span-3"}>
            <div className={"text-line"}>
              <h2>Bürgerstiftung Kreis Ravensburg</h2>
              <p className={"text-2"}>
                Verantwortung für Gesellschaft und Umwelt sowie das soziale
                Engagement Unserer Mitarbeiterinnen und Mitarbeiter sind zentraler
                Bestandteil Unserer Unternehmensphilosophie. Wir unterstützen die
                Bürgerstiftung Kreis Ravensburg in technischen Angelegenheiten.
                Damit unterstützen Wir das Ziel, die Kräfte in der Region zu
                bündeln, um Projekte, die Ihnen am Herzen liegen, in die Tat
                umzusetzen – für die Entwicklung des Landkreises Ravensburg und
                das Wohl der dort lebenden Menschen.
              </p>
              <a
                href={
                  "https://www.buergerstiftung-kreis-rv.de/enagement_fuer_unternehmen"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                <button className={"button-link"}>Weitere Infos ></button>
              </a></div>
          </div>
          <div className={"row-start-2 msw:row-start-1 msw:col-start-4 msw:col-span-3"}>
            <img
              loading={"lazy"}
              className={"float-right p-[15]"}
              src={config.PUBLIC_URL + "images/buergerstiftung.svg"}
              alt={"Bürgerstiftung Kreis Ravensburg"}
            />
          </div>
          <div className={"row-start-3 msw:row-start-2 msw:col-start-1 msw:col-span-3 pr-0 mb-4"}>
            <picture>
              <source
                media="(min-width: 1024px)"
                srcSet="images/desktop/recingteam.jpg"
              />
              <source
                media="(min-width: 321px)"
                srcSet="images/tablet/recingteam.jpg"
              />
              <source
                media="(max-width: 320px)"
                srcSet="images/mobile/recingteam.jpg"
              />
              <img
                loading={"lazy"}
                className={"w-full p-[50]"}
                src={"images/recingteam.jpg"}
                alt={"Radfahrerverein Weingarten"}
              />
            </picture>

          </div>
          <div className={"row-start-4 msw:row-start-2 msw:col-start-4 msw:col-span-3 xl:col-span-2 self-center"}>
            <div className={"text-line"}>
              <h2>Radfahrer-Verein Weingarten 1894 e.V.</h2>
              <p className={"text-2"}>
                Seit der Saison 2022 agiren wir Hauptsponsor für das Racingteam des RV Weingarten.
              </p>
              <a
                href={
                  "https://rv-weingarten.de/racing/"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                <button className={"button-link"}>Weitere Infos ></button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EngagementPage);
