import config from '../config/main.config';

export function isDevelop() {
  return (
    process.env.NODE_ENV === 'development' ||
    typeof process.env.NODE_ENV === 'undefined'
  );
}

export function getResponsiveImagePath(windowWidth) {
  let path = config.PUBLIC_URL + 'images/desktop/';
  if (windowWidth < 1372) {
    path = config.PUBLIC_URL + 'images/tablet/';
    if (windowWidth < 767) {
      path = config.PUBLIC_URL + 'images/mobile/';
    }
  }
  return path;
}

export const MAX_MOBILE_WIDTH = 787;

export const regexpMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
