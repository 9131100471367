import React, { useEffect, useState } from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { MAX_MOBILE_WIDTH } from '../../helper/util';
import { useCookies } from 'react-cookie';

const mapStyle = [
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#000000'
      },
      {
        lightness: 13
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#000000'
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#144b53'
      },
      {
        lightness: 14
      },
      {
        weight: 1.4
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#002546'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#0c4152'
      },
      {
        lightness: 5
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ef9613'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#0b434f'
      },
      {
        lightness: 25
      },
      {
        weight: '1.65'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fafafa'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#0b3d51'
      },
      {
        lightness: 16
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#fafafa'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ef9613'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        color: '#146474'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#5e78a1'
      }
    ]
  }
];

let containerStyle = {
  width: '98%',
  height: '800px'
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function _mapLoaded(mapProps, map) {
  map.setOptions({
    styles: mapStyle
  });
}

const MapContainer = props => {
  const [cookies, removeCookie] = useCookies();
  const containerWidth = useWindowSize().width;
  if (containerWidth <= MAX_MOBILE_WIDTH) {
    containerStyle = {
      width: '100%',
      height: '400px'
    };
  } else {
    containerStyle = {
      width: '100%',
      height: '800px'
    };
  }

  if (cookies.consentGiven && cookies.consentGiven.includes('performance'))
    return (
      <Map
        google={props.google}
        zoom={17}
        style={containerStyle}
        initialCenter={{
          lat: 47.81877,
          lng: 9.64367
        }}
        onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
      >
        <Marker
          title={'Danzigerstraße 3'}
          name={'Triple-A Soft'}
          onClick={() =>
            window.open(
              'https://www.google.com/maps/place/Danziger+Str.+3,+88250+Weingarten/@47.818287,9.6409134,17z/data=!3m1!4b1!4m5!3m4!1s0x479bafc697d31d8f:0xb58d3a2bd9fe3349!8m2!3d47.8182834!4d9.6431021',
              '_blank'
            )
          }
          position={{ lat: 47.81877, lng: 9.64367 }}
          icon={''}
        />
      </Map>
    );
  else
    return (
      <div className={"text-white text-center pt-[10%]"}>
        <p>
          Um die Google Map zu öffnen müssen Sie die Performance Cookies
          akzeptieren.
        </p>
        <button
          className="!bg-gray-500 rounded-[5px] pr-[24px] pl-[24px] h-fit w-fit font-semi-bold shadow-basic-button border-none"
          onClick={() => {
            Object.keys(cookies).map(cookie => {
              removeCookie(cookie);
            });
          }}
        >
          Cookie Einstellungen öffnen
        </button>
      </div>
    );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAThy-xy11vgdLXPNLu0C4ej8Z-AjdGVhg'
})(MapContainer);
