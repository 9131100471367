import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Loadable from 'react-loadable';

import App from './components/App';
import { CookiesProvider } from 'react-cookie';

import './styles/tailwind.css';
import './styles/components/scroll_up_button.css'
import "./styles/components/contact.css";
import "./styles/components/cookie.css";
import "./styles/components/newsletter.css";
import "./styles/components/footer.css";
import "./styles/components/navigation.css";
import './i18n';

const serverData = window.__SERVER_DATA__;

export const main = () => {
  Loadable.preloadReady().then(() => {
    ReactDOM.hydrate(
        <BrowserRouter>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </BrowserRouter>,
      document.getElementById('root')
    );
  });
};
