import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";

import Footer from "./footer/Footer";
import Navigation from "./navigation/Navigation";
import ScrollUpButton from "./scroll_up_button/ScrollUpButton";
import MetaTags from "./metatags/MetaTags";
import LoaderComponent from "./LoaderComponent";
import CookieBanner from "./cookieBanner/CookieBanner";

import TagManager from "react-gtm-module";
import { useCookies } from "react-cookie";

const tagManagerArgs = {
  gtmId: "GTM-TK863QV"
  //the below changes GTM values based on whether dev or production
  /*auth: env === "development"
    ? "<dev ga_auth>"
    : "<live ga_auth>",
  preview: env === "development"
    ? "<dev ga_env>"
    : "<live ga_env>"*/
};

const LoadableHome = Loadable({
  loader: () => import(/* webpackChunkName: 'home' */ "./Home"),
  loading: () => LoaderComponent
});

const LoadableImprint = Loadable({
  loader: () => import(/* webpackChunkName: 'home' */ "./Imprint"),
  loading: () => LoaderComponent
});

const LoadableDataPrivacy = Loadable({
  loader: () => import(/* webpackChunkName: 'home' */ "./DataPrivacy"),
  loading: () => LoaderComponent
});

const LoadableAbout = Loadable({
  loader: () => import(/* webpackChunkName: 'about' */ "./about/About"),
  loading: () => LoaderComponent
});

const LoadableContact = Loadable({
  loader: () => import(/* webpackChunkName: 'about' */ "./contact/Contact"),
  loading: () => LoaderComponent
});

const LoadableServices = Loadable({
  loader: () => import(/* webpackChunkName: 'about' */ "./services/Services"),
  loading: () => LoaderComponent
});

const LoadableCalculator = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'calculator' */ "./calculator/WebCalculator"),
  loading: () => LoaderComponent
});

const LoadableFaq = Loadable({
  loader: () => import(/* webpackChunkName: 'faq' */ "./faq/Faq"),
  loading: () => LoaderComponent
});

const LoadableEngagement = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'engagement' */ "./engage/Engagement"),
  loading: () => LoaderComponent
});

const LoadableNotFound = Loadable({
  loader: () => import(/* webpackChunkName: 'about' */ "./not_found/NotFound"),
  loading: () => LoaderComponent
});

const App = () => {
  const [cookies] = useCookies();
  if (cookies.consentGiven && cookies.consentGiven.includes("statistic"))
    TagManager.initialize(tagManagerArgs);
  return (
    <div>
      <MetaTags />
      <Navigation />
      <Switch>
        <Route exact path="/" component={LoadableHome} />
        <Route path="/about" component={LoadableAbout} />
        <Route path="/imprint" component={LoadableImprint} />
        <Route path="/data-privacy" component={LoadableDataPrivacy} />
        <Route path="/contact" component={LoadableContact} />
        <Route path="/faq" component={LoadableFaq} />
        <Route path="/engagement" component={LoadableEngagement} />
        <Route path="/services" component={LoadableServices} />
        <Route path="/configurator" component={LoadableCalculator} />
        <Route path="/404" component={LoadableNotFound} status={404} />
        <Redirect to="/404" status={404} />
      </Switch>
      <Footer />
      <ScrollUpButton />
      <CookieBanner />
    </div>
  );
};

export default App;
