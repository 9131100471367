import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import Card from "./cards/Card";
import config from "../config/main.config";
import { NavLink } from "react-router-dom";
import BreadCrumbs from "./breadcrumbs/BreadCrumbs";
import ParticleLine from "./particle_line/ParticleLine";

class Home extends React.Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <title>HOME | Triple-A Soft</title>
          <meta
            name="description"
            content={
              "Triple-A Soft ist Ihr Partner für individuelle Weblösungen."
            }
          />
          <meta name="robots" content="index,follow" />
          <meta
            name="title"
            content="Triple-A Soft | Webentwicklung und Digitalisierung"
          />
          <meta
            property="og:site_name"
            content="Triple-A Soft UG (Haftungsbeschränkt)"
          />
          <meta
            property="og:title"
            content="Triple A Soft | Webentwicklung und Digitalisierung"
          />
          <meta
            property="og:description"
            content="Wir sind Ihr Partner für die Digitalisierung Ihrer Ideen, Datensicherheit & Optimierung von Geschäftsprozessen."
          />

          <meta property="og:url" content="https://www.aaa-soft.net" />
          <meta
            property="og:image"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta property="og:type" content="website" />
          <meta
            name="twitter:title"
            content="Triple A Soft | Webentwicklung und Digitalisierung"
          />
          <meta
            name="twitter:description"
            content="Wir sind Ihr Partner für die Digitalisierung Ihrer Ideen, Datensicherheit & Optimierung von Geschäftsprozessen."
          />
          <meta
            name="twitter:image"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <ParticleLine>
          <h1 className={""}>Triple-A Soft</h1>
          <p className={"page-header"}>{t("header.home_header")}</p>
          <p className={"header-claim pb-[50]"}>{t("header.claim")}</p>
          <NavLink to={"/services"}>
            <button className={"header-link"}>{t("home.more")} ></button>
          </NavLink>
        </ParticleLine>
        <div className={"hidden"}>
          <BreadCrumbs breadCrumbData={[{ title: "Home", url: "/" }]} />
        </div>
        <div className={"grid grid-cols-16"}>
          <div className={"row-start-2 col-start-2 col-span-14 msw:row-start-1 msw:col-start-1 msw:col-span-8 pr-0"}>
            <picture>
              <source
                media="(min-width: 1024px)"
                srcSet="images/desktop/TripleA_Shopsysteme.webp, images/desktop/TripleA_Shopsysteme.png"
              />
              <source
                media="(min-width: 321px)"
                srcSet="images/tablet/TripleA_Shopsysteme.webp, images/tablet/TripleA_Shopsysteme.png"
              />
              <source
                media="(max-width: 320px)"
                srcSet="images/mobile/TripleA_Shopsysteme.webp, images/mobile/TripleA_Shopsysteme.png"
              />
              <img
                loading={"lazy"}
                className={"w-full"}
                src={"images/TripleA_Shopsysteme.png"}
                alt={""}
              />
            </picture>
          </div>
          <div
            className={"row-start-1 col-start-2 col-span-14 msw:row-start-1 msw:col-start-9 msw:col-span-7 msw:self-center"}>
            <div className={"text-line"}>
              <h2>{t("header.shopsystems")}</h2>
              <p className={"text-2"}>
                Wir sind ein führendes Unternehmen, das sich auf die Digitalisierung von Prozessen spezialisiert hat.
                Unser Ziel ist es, Ihr Unternehmen in das digitale Zeitalter zu führen und Ihre Abläufe effizienter und
                reibungsloser zu gestalten. Mit unserer langjährigen Erfahrung und Expertise helfen wir Ihnen, Ihre
                Prozesse zu optimieren und Ihr volles digitales Potenzial auszuschöpfen.
              </p>
            </div>
          </div>
          <div
            className={"row-start-3 col-start-2 col-span-14 msw:row-start-2 msw:col-start-3 msw:col-span-5 msw:self-center"}>
            <div className={"text-line"}>
              <h2>{t("header.databases")}</h2>
              <p className={"text-2"}>
                Unser Team aus hochqualifizierten Experten verfügt über umfangreiches Know-how in der Entwicklung
                maßgeschneiderter Webanwendungen. Von der Konzeption und Gestaltung bis hin zur Implementierung und
                Wartung stehen wir Ihnen zur Seite und entwickeln innovative Lösungen, die genau auf Ihre Bedürfnisse
                zugeschnitten sind.
              </p>
            </div>
          </div>
          <div className={"row-start-4 col-start-2 col-span-14 msw:row-start-2 msw:col-start-9 msw:col-span-8"}>
            <picture>
              <source
                media="(min-width: 1024px)"
                srcSet="images/desktop/TripleA_Datenbanken.webp, images/desktop/TripleA_Datenbanken.png"
              />
              <source
                media="(min-width: 321px)"
                srcSet="images/tablet/TripleA_Datenbanken.webp, images/tablet/TripleA_Datenbanken.png"
              />
              <source
                media="(max-width: 320px)"
                srcSet="images/mobile/TripleA_Datenbanken.webp, images/mobile/TripleA_Datenbanken.png"
              />
              <img
                loading={"lazy"}
                className={"w-full"}
                src={"images/TripleA_Datenbanken.png"}
                alt={"No fallback images set"}
              />
            </picture>
          </div>
          <div className={"row-start-6 col-start-2 col-span-14 msw:row-start-3 msw:col-start-1 msw:col-span-8"}>
            <picture>
              <source
                media="(min-width: 1024px)"
                srcSet="images/desktop/TRIPLE-A_Unternehmensseiten.webp, images/desktop/TRIPLE-A_Unternehmensseiten.png"
              />
              <source
                media="(min-width: 321px)"
                srcSet="images/tablet/TRIPLE-A_Unternehmensseiten.webp, images/tablet/TRIPLE-A_Unternehmensseiten.png"
              />
              <source
                media="(max-width: 320px)"
                srcSet="images/mobile/TRIPLE-A_Unternehmensseiten.webp, images/mobile/TRIPLE-A_Unternehmensseiten.png"
              />
              <img
                loading={"lazy"}
                className={"w-full"}
                src={"images/TRIPLE-A_Unternehmensseiten.png"}
                alt={"Unternehmensseiten"}
              />
            </picture>
          </div>
          <div
            className={"row-start-5 col-start-2 col-span-14 msw:row-start-3 msw:col-start-9 msw:col-span-7 msw:self-center"}>
            <div className={"text-line"}>
              <h2>{t("header.company_pages")}</h2>
              <p className={"text-2"}>
                Darüber hinaus verstehen wir, wie wichtig es ist, in den Suchmaschinenergebnissen sichtbar zu sein.
                Deshalb legen wir großen Wert auf Suchmaschinenoptimierung (SEO) und sorgen dafür, dass Ihre Website die
                bestmögliche Sichtbarkeit im Internet erlangt. Unsere Experten analysieren und optimieren Ihre Website,
                um sicherzustellen, dass Sie von potenziellen Kunden gefunden werden.
              </p>
            </div>
          </div>
          <div
            className={"row-start-7 col-start-2 col-span-14 msw:row-start-4 msw:col-start-3 msw:col-span-8 msw:self-center"}>
            <h2>Unser System</h2>
            <p className={"text-2"}>
              Als Experten sind wir stets am Puls der Zeit. Durch den ständigen Austausch mit Fach- und
              Führungskräften in der Softwareentwicklung und im Projektmanagement sind wir immer im Bilde, welche
              Technologien für Best Practices verwendet werden und wohin der Trend geht. Wir sprechen Ihre Sprache.
              Das schätzen unsere Kunden an uns und können sich auf unser Know How verlassen.
            </p>
            <div className={"max-msw:block max-msw:w-full msw:flex msw:justify-start"}>
              <NavLink to={"/contact"}>
                <button className={"button-1 max-msw:w-full"}>KONTAKT</button>
              </NavLink>
            </div>
          </div>
        </div>
        <div className={"grid grid-cols-2 msw:grid-cols-15 bg-primary pt-[6rem] pb-[6rem] mt-4 mb-4"}>
          <div className={"row-start-1 col-span-2 msw:col-start-3 msw:col-span-11 msw:mt-4 msw:mb-4 m-4 h-max"}>
            <h2 className={"bg-primary text-white"}>{t("header.we_are_triple")}</h2>
          </div>
          <div className={"row-start-2 col-span-2 msw:col-start-3 msw:col-span-11 grid grid-cols-1 msw:grid-cols-2"}>
            <p
              className={"text-2-white !z-0 bg-primary row-start-1 msw:col-start-1 msw:row-start-1 msw:mr-2 msw:mt-4 m-4 h-max"}>
              Egal, ob Sie bereits digitale Prozesse haben, die verbessert werden müssen, oder ob Sie von Grund auf eine
              Webanwendung entwickeln möchten - Triple-A Soft ist Ihr vertrauensvoller Partner für die
              digitale Transformation. Kontaktieren Sie uns noch heute, um mehr über unsere Dienstleistungen zu erfahren
              und wie wir Ihnen helfen können, Ihr Unternehmen erfolgreich in die digitale Zukunft zu führen.
            </p>
            <p
              className={"text-2-white !z-0 bg-primary row-start-2 msw:col-start-2 msw:row-start-1 msw:ml-2 msw:mt-4 m-4 h-max"}>
              Gemeinsam mit Ihnen fragen wir uns: Was brauchen Sie und wie setzen wir das am
              besten um? Sie bekommen von uns das Konzept, das am besten zu Ihnen passt. Wir
              kümmern uns gewissenhaft um Ihr Anliegen, und sorgen dafür Sie und Ihr Angebot
              angemessen zu repräsentieren.
              <b className={"font-[600]"}> Wir freuen uns auf Sie.</b>
            </p>
            <div className={"col-start-1 row-start-3 msw:col-start-1 msw:col-span-1 msw:row-start-2 max-msw:m-4 "}>
              <div className={"flex"}>
                <NavLink to={"/about"}>
                  <button className={"button-1"}>
                    {t("home.team_more").toUpperCase()}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className={"row-start-3 col-span-2 msw:col-start-3 msw:col-span-11"}>
            <div className={"row-start-1 col-span-2 msw:col-start-3 msw:col-span-11 msw:mt-4 msw:mb-4 m-4 h-max"}>
              <h2 className={"bg-primary text-white"}>Kunden, die uns vertrauen</h2>
            </div>
            <div className={"flex flex-row flex-wrap justify-center"}>
              <div className={"w-[200px] m-2 justify-self-center self-center"}>
                <a
                  href={"https://www.westnetz.de/de.html"}
                  rel={"noreferrer"}
                  target={"_blank"}
                  aria-label="Westnetz"
                >
                  <img className={"m-auto"}
                       loading={"lazy"}
                       src={
                         config.PUBLIC_URL +
                         "images/company_logos/Westnetz_Logo.svg"
                       }
                       alt={""}
                  />
                </a>
              </div>
              <div className={"w-[200px] m-2 justify-self-center self-center"}>
                <a
                  href={"https://www.toyota-europe.com/brands-and-services/toyota-insurance-services"}
                  rel={"noreferrer"}
                  target={"_blank"}
                  aria-label="TOYOTA Insurance"
                >
                  <img className={"m-auto"}
                       loading={"lazy"}
                       src={
                         config.PUBLIC_URL +
                         "images/company_logos/toyota-insurance.svg"
                       }
                       alt={""}
                  />
                </a>
              </div>
              <div className={"w-[200px] m-2 justify-self-center self-center"}>
                <a
                  href={"https://www.glw.de/"}
                  rel={"noreferrer"}
                  target={"_blank"}
                  aria-label="GLW GmbH"
                >
                  <img className={"m-auto"}
                       loading={"lazy"}
                       src={
                         config.PUBLIC_URL +
                         "images/company_logos/GLW_LOGO_CMYK_RZ.svg"
                       }
                       alt={""}
                  />
                </a>
              </div>
              <div className={"w-[200px] m-2 justify-self-center self-center"}>
                <a
                  href={"https://www.kanzlei-anliker.de/"}
                  rel={"noreferrer"}
                  target={"_blank"}
                  aria-label="Kanzlei Anliker"
                >
                  <img className={"m-auto"}
                       loading={"lazy"}
                       src={
                         config.PUBLIC_URL +
                         "images/company_logos/Kanzlei-Anliker_logo.png"
                       }
                       alt={""}
                  />
                </a>
              </div>
              <div className={"w-[200px] m-2 justify-self-center self-center"}>
                <a
                  href={"https://www.geldfuermuell.de/"}
                  rel={"noreferrer"}
                  target={"_blank"}
                  aria-label="Geld für Müll GmbH"
                >
                  <img className={"m-auto"}
                       loading={"lazy"}
                       src={
                         config.PUBLIC_URL +
                         "images/company_logos/gfm_logo_negativ.svg"
                       }
                       alt={""}
                  />
                </a>
              </div>
              <div className={"w-[200px] m-2 justify-self-center self-center"}>
                <a
                  href={"https://www.schurr-geraetebau.de/"}
                  rel={"noreferrer"}
                  target={"_blank"}
                  aria-label="Schurr Gerätebau"
                >
                  <img className={"m-auto"}
                       loading={"lazy"}
                       src={config.PUBLIC_URL + "images/company_logos/schurr.png"}
                       alt={""}
                  />
                </a>
              </div>
              <div className={"w-[200px] m-2 justify-self-center self-center"}>
                <a
                  className={""}
                  href={"https://valetott.de/"}
                  rel={"noreferrer"}
                  target={"_blank"}
                  aria-label="Valet Ott"
                >
                  <img className={"m-auto"}
                       loading={"lazy"}
                       src={
                         config.PUBLIC_URL +
                         "images/company_logos/valetott-logo.png"
                       }
                       alt={""}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Home);
