import React, { useEffect, useRef, useState } from "react";
import ParticleCanvas from "./ParticleCanvas";

const ParticleLine = (props) => {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [key, setKey] = useState(0);

    useEffect(() => {
      setWidth(ref.current.clientWidth);
      setHeight(ref.current.clientHeight);
      window.addEventListener("resize", (event) => {
        setWidth(ref.current.offsetWidth);
        setHeight(ref.current.offsetHeight);

        //when the unique key changes, the component inside will be thrown away and recreated
        setKey(Date.now());
      });
    }, []);

    return (
      <>
        <div className={"particles-container relative"} ref={ref}>
          {(width !== 0 && height !== 0) ?
            <ParticleCanvas width={width} height={height} key={key}></ParticleCanvas> : null}
          <div className={"grid grid-cols-15"}>
            <div className={"triangle-svg row-start-4 row-span-1 col-start-1 col-span-15 mb-[-1px]"}>
              <svg viewBox="0 0 100 8" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" width="100%"
                   height="100%">
                <polygon fill="#FAFAFA" points="79,0 0,8 100,8" />
              </svg>
            </div>

            <div className={"col-start-3 col-span-11 particles-children"}>
              {props.children}
            </div>
          </div>
        </div>
      </>
    );
  }
;
export default ParticleLine;