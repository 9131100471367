import React from "react";
import { Helmet } from "react-helmet";
import ParticleLine from "../particle_line/ParticleLine";
import BreadCrumbs from "../breadcrumbs/BreadCrumbs";
import { withTranslation } from "react-i18next";
import fetch from "isomorphic-unfetch";
import config from "../../config/main.config";
import { NavLink } from "react-router-dom";

const regexpMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const STEPS = [
  {
    title: "calculator.step1.title",
    type: 1,
    options: [
      {
        title: "calculator.step1.option1",
        icon: "desktop",
        chosen: false
      },
      {
        title: "calculator.step1.option2",
        icon: "book",
        chosen: false
      },
      {
        title: "calculator.step1.option3",
        icon: "lemon",
        chosen: false
      }
    ]
  },
  {
    title: "calculator.step2.title",
    type: 1,
    options: [
      {
        title: "calculator.step2.option1",
        icon: "clipboard",
        chosen: false
      },
      {
        title: "calculator.step2.option2",
        icon: "desktop",
        chosen: false
      },
      {
        title: "calculator.step2.option3",
        icon: "arrowRight",
        chosen: false
      }
    ]
  },
  {
    title: "calculator.step3.title",
    type: 1,
    options: [
      {
        title: "calculator.step3.option1",
        icon: "building",
        chosen: false
      },
      {
        title: "calculator.step3.option2",
        icon: "users",
        chosen: false
      },
      {
        title: "calculator.step3.option3",
        icon: "idea",
        chosen: false
      }
    ]
  },
  {
    title: "calculator.step4.title",
    type: 2,
    from: 1000,
    till: 10000,
    value: 1500
  }
];

class CalculatorPage extends React.Component {
  state = {
    applicationWith: 1200,
    currentStep: 0,
    STEPS: STEPS,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
    conditionsChecked: false,
    lastNameError: false,
    phoneError: false,
    emailError: false,
    subjectError: false,
    messageError: false,
    conditionsCheckedError: false,
    modalOpen: false,
    modalMessage: "",
    newsletterCheck: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.updateDimensions);
    }
    this.updateDimensions();
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateDimensions);
    }
  }

  updateDimensions = () => {
    this.setState({
      applicationWith: window.outerWidth
    });
  };

  setOptionActive(stepIndex, optionIndex) {
    let steps = this.state.STEPS;

    for (let i = 0; i < steps[stepIndex].options.length; i++) {
      steps[stepIndex].options[i].chosen = i === optionIndex;
    }

    this.setState({ ...this.state, STEPS: steps });

    setTimeout(() => {
      this.setState({ ...this.state, currentStep: ++stepIndex });
    }, 500);
  }

  setOptionValue(stepIndex, value) {
    let steps = this.state.STEPS;
    steps[stepIndex].value = value;

    this.setState({ ...this.state, STEPS: steps });
  }

  showStepOptions(options, currentSet, t) {
    let showOptions = [];

    for (let i = 0; i < options.length; i++) {
      let option = options[i];
      showOptions.push(
        <div
          className={"card-container !shadow-calculator-card w-full h-full div_ratio_one_one_container div_ratio_content"}
          onClick={() => this.setOptionActive(currentSet, i)}>
          <div className={"border-solid border-b-2 m-[-10px]"}>
            <img className={"w-full h-full p-[10px]"}
                 style={{
                   color: option.chosen ? "#ef9613" : "#304c72"
                 }}
                 loading="lazy"
                 alt="notFound"
                 src={config.PUBLIC_URL + 'images/icons/' + option.icon + '.svg'}/>
          </div>
          <div className="page-header">
            <p className={"text-2 text-center mt-[1rem]"}>
              {t(option.title)}
              {option.chosen ? (
                <i
                  aria-hidden="true"
                  className={" check circle icon text-secondary_color-OLD"}
                />
              ) : null}
            </p>
          </div>
        </div>
      )
      ;
    }

    return (
      <div
        className={"row-start-2 col-start-1 md:col-start-2 col-span-3"}>
        <div className="grid grid-cols-3 md:gap-8 gap-2">{showOptions}</div>
      </div>
    );
  }

  submitMessage(t) {
    let canSend = true;
    let state = this.state;

    if (state.email.match(regexpMail)) {
      state.emailError = false;
    } else {
      state.emailError = true;
      canSend = false;
    }

    if (state.conditionsChecked) {
      state.conditionsCheckedError = false;
    } else {
      state.conditionsCheckedError = true;
      canSend = false;
    }

    this.setState(state);

    let answers = this.getAnsweredSteps(this.state.STEPS, t);

    if (canSend) {
      //Send to server
      let data = {
        name: state.firstName + " " + state.lastName,
        subject: state.subject,
        email: state.email,
        text: state.message,
        answers: answers,
        newsletter: state.newsletterCheck
      };

      let self = this;

      fetch(config.BASE_URL + "contact/web", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(function(response) {
          if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
              if (json.hasOwnProperty("success") && json) {
                self.state.modalMessage = "Ihre Anfrage wird bearbeitet";
              } else {
                self.state.modalMessage =
                  "Ein Fehler beim senden Ihrer ist unterlaufen";
              }
              self.state.modalOpen = true;

              self.setState(self.state);
            });
          } else {
            self.state.modalMessage =
              "Ein Fehler beim senden Ihrer ist unterlaufen";
            self.state.modalOpen = true;
            self.setState(self.state);
          }
        })
        .catch(() => {
          self.state.modalMessage =
            "Ein Fehler beim senden Ihrer ist unterlaufen";
          self.state.modalOpen = true;
          self.setState(self.state);
        });
    }
  }

  showScalaOptions(currentStep, currentStepIndex) {
    const progress =
      ((currentStep.value - currentStep.from) * 100) /
      (currentStep.till - currentStep.from) +
      "%";

    const active = "#ef9613";
    const inactive = "#fafafa";

    const styleInput = {
      background: `linear-gradient(90deg, ${active} 0% ${progress},   ${inactive} ${progress} 100%)`
    };

    return (
      <div className={"row-start-2 col-start-1 col-span-5 grid grid-cols-9"}>
        <div className={"row-start-1 col-start-1 small:col-start-2 small:col-span-1 col-span-3 text-left"}>
          <p className={"scale_text"}>{currentStep.from + " €"}</p>
        </div>
        <div className={"row-start-1 col-start-4 small:col-start-5 small:col-span-1 col-span-3 text-center"}>
          <p className={"scale_text"}>
            {" "}
            {parseInt(currentStep.value) === currentStep.till
              ? currentStep.value + "+ €"
              : currentStep.value + " €"}
          </p>
        </div>
        <div className={"row-start-1 col-start-7 col-span-3 small:col-start-8 small:col-span-1 text-right"}>
          <p className={"scale_text"}> {currentStep.till + "+ €"}</p>
        </div>
        <div className={"row-start-2 col-start-2 col-span-7"}>
          <input
            className={"inputR w-full accent-secondary"}
            style={styleInput}
            type="range"
            min={currentStep.from}
            max={currentStep.till}
            value={currentStep.value}
            onChange={e =>
              this.setOptionValue(currentStepIndex, e.target.value)
            }
          />
        </div>
      </div>
    );
  }

  showCurrentStep(currentStepIndex, t) {
    let options;
    if (this.state.STEPS[currentStepIndex].type === 1) {
      options = this.showStepOptions(
        this.state.STEPS[currentStepIndex].options,
        currentStepIndex,
        t
      );
    } else {
      options = this.showScalaOptions(
        this.state.STEPS[currentStepIndex],
        currentStepIndex,
        t
      );
    }

    return [
      <div className={"row-start-1 col-start-1 md:col-start-2 col-span-3"}>
        <h2>{t(STEPS[currentStepIndex].title)}</h2>
      </div>,
      options
    ];
  }

  handleSearchChange(type, event) {
    this.setState({
      ...this.state,
      [type]: event.target.value
    });
  }

  getAnsweredSteps(steps, t) {
    let answeredSteps = [];
    for (let i = 0; i < steps.length; i++) {
      let question = t(steps[i].title);
      let answer = "";

      if (steps[i].type === 1) {
        for (let j = 0; j < steps[i].options.length; j++) {
          if (steps[i].options[j].chosen) {
            answer = t(steps[i].options[j].title);
          }
        }
      } else {
        answer = steps[i].value;
      }
      answeredSteps.push({
        question: question,
        answer: answer
      });
    }

    return answeredSteps;
  }

  showContactForm(t) {
    return [
      <div className={"row-start-1 col-start-1 col-span-5 grid grid-cols-12 msw:grid-cols-8"}>
        <div className={"row-start-1 col-start-2 col-span-10 msw:row-start-1 msw:col-start-2 msw:col-span-6"}>
          <p className={"text-2 mb-[5%]"}>
            Mit einem * markirte Felder sind Pflichtfelder.
          </p>
        </div>
        <div className={"row-start-2 col-start-2 col-span-10 msw:row-start-2 msw:col-start-2 msw:col-span-3"}>
          <input
            className={"text-box-white"}
            type="text"
            value={this.state.name}
            placeholder={"Name"}
            onChange={e =>
              this.setState({ ...this.state, name: e.target.value })
            }
          />
        </div>
        <div className={"row-start-3 col-start-2 col-span-10 msw:row-start-2 msw:col-start-5 msw:col-span-3"}>
          <input
            className={"text-box-white"}
            type="text"
            value={this.state.company}
            placeholder={"Firma"}
            onChange={e =>
              this.setState({ ...this.state, company: e.target.value })
            }
          />
        </div>
        <div className={"row-start-4 col-start-2 col-span-10 msw:row-start-3 msw:col-start-2 msw:col-span-3"}>
          <input
            className={"text-box-white"}
            type="text"
            value={this.state.address}
            placeholder={"Adresse"}
            onChange={e =>
              this.setState({ ...this.state, address: e.target.value })
            }
          />
        </div>
        <div className={"row-start-5 col-start-2 col-span-10 msw:row-start-3 msw:col-start-5 msw:col-span-3"}>
          <input
            className={this.state.emailError ? "error-input" : "text-box-white"}
            type="text"
            value={this.state.email}
            onClick={() => this.setState({ ...this.state, emailError: false })}
            placeholder={"* E-Mail"}
            onChange={e =>
              this.setState({ ...this.state, email: e.target.value })
            }
          />
        </div>
        <div className={"row-start-6 col-start-2 col-span-10 msw:row-start-4 msw:col-start-2 msw:col-span-6"}>
          <textarea
            className={"text-box-white min-h-[250px] mb-0"}
            value={this.state.message}
            placeholder={"Nachricht"}
            onChange={e =>
              this.setState({ ...this.state, message: e.target.value })
            }
          />
        </div>
      </div>,
      <div className={"row-start-2 col-start-1 col-span-5 grid grid-cols-8"}>
        <div className={"row-start-1 col-start-2 col-span-8"}>
          <div
            className={
              this.state.conditionsCheckedError
                ? "error field accept-terms"
                : "field accept-terms"
            }
          >
            <div className="checkbox-contact mt-[15px] mr-[15px]">
              <label className={"ml-2"}>
                <input
                  className={"w-[17px] h-[17px] mr-2 cursor-pointer"}
                  tabIndex="0"
                  type="checkbox"
                  onChange={() =>
                    this.setState(prevState => ({
                      ...prevState,
                      conditionsChecked: !prevState.conditionsChecked
                    }))
                  }
                  value={this.state.conditionsChecked}
                />
                {"* " + t("contact_form.terms_conditions")}</label>
              <NavLink exact to="/data-privacy" className={"accept-terms-link underline"}>
                {t("contact_form.privacy_link_text")}
              </NavLink>
            </div>

          </div>
        </div>
        <div className={"row-start-2 col-start-2 col-span-8"}>
          <div className="checkbox-contact mt-[15px] mr-[15px]">
            <label className={"ml-2"}>
              <input
                className={"w-[17px] h-[17px] mr-2 cursor-pointer"}
                type="checkbox"
                onChange={() =>
                  this.setState(prevState => ({
                    ...prevState,
                    newsletterCheck: !prevState.newsletterCheck
                  }))
                }
                value={this.state.newsletterCheck}
              />
              {t("contact_form.newsletter")}</label>
          </div>
        </div>
      </div>
    ];
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <Helmet>
          <title>WEBSEITEN KONFIGURATOR | Triple-A Soft</title>
          <meta
            name="description"
            content={
              "Wir erstellen Ihnen ein kostenloses und unverbindliches Angebot für Ihre Webseite. "
            }
          />
          <meta name="title" content="Webseiten Konfigurator" />
          <meta name="robots" content="index,follow" />

          <meta
            property="og:site_name"
            content="Triple-A Soft UG (Haftungsbeschränkt)"
          />
          <meta property="og:title" content="Webseiten Konfigurator" />
          <meta
            property="og:description"
            content="Wir erstellen Ihnen ein kostenloses und unverbindliches Angebot für Ihre Webseite. "
          />
          <meta property="og:url" content="https://www.aaa-soft.net" />
          <meta
            property="og:image:url"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content="Webseiten Konfigurator" />
          <meta
            name="twitter:description"
            content="Wir erstellen Ihnen ein kostenloses und unverbindliches Angebot für Ihre Webseite. "
          />
          <meta
            name="twitter:image"
            content="https://www.aaa-soft.net/images/image.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <ParticleLine>
          <h1>{t("web_calculator.text")}</h1>
          <p className={"page-header"}>{t("web_calculator.title")}</p>
        </ParticleLine>
        <div className={"hidden"}>
          <BreadCrumbs
            breadCrumbData={[
              { title: t("titles.services"), url: "/services" },
              { title: t("titles.development"), url: "/development" },
              { title: t("titles.calculator"), url: "/configurator" }
            ]}
          />
        </div>
        <div className={"grid grid-cols-3 md:grid-cols-5 md:gap-8 gap-2 m-4 md:m-0"}>
          {this.state.currentStep === this.state.STEPS.length
            ? this.showContactForm(t)
            : this.showCurrentStep(this.state.currentStep, t)}
          <div
            className={"row-start-4 col-start-1 md:col-start-2 msw:col-start-2 col-span-3 grid grid-cols-3 gap-8 md:gap-0"}>
            <div className={"row-start-1 col-start-1 col-span-1"}>
              <button
                type="submit"
                className={
                  this.state.currentStep === 0 ? "disabled-basic-button float-left" : "basic-button float-left"
                }
                disabled={this.state.currentStep === 0}
                onClick={() =>
                  this.setState({
                    ...this.state,
                    currentStep: this.state.currentStep - 1
                  })
                }
              >
                {t("calculator.back").toUpperCase()}
              </button>
            </div>
            <div className={"row-start-1 col-start-3 col-span-1"}>
              {this.state.currentStep === this.state.STEPS.length ? (
                <button
                  type="submit"
                  className={"basic-button"}
                  onClick={() => this.submitMessage(t)}
                >
                  {t("contact_form.submit").toUpperCase()}
                </button>
              ) : (
                <button
                  type="submit"
                  className={"basic-button float-right"}
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      currentStep: this.state.currentStep + 1
                    })
                  }
                >
                  {t("calculator.next").toUpperCase()}
                </button>
              )}
            </div>
          </div>
          {this.state.modalOpen ? (
            <div className="ui page modals dimmer transition visible active">
              <div
                className={"contact_modal"}>
                <div className="content">
                  <h3>{this.state.modalMessage}</h3>
                </div>
                <div className="actions">
                  <NavLink
                    exact
                    to="/"
                    className={"ui circular button basic-button"}
                  >
                    <i aria-hidden="true" className="checkmark icon" /> OK
                  </NavLink>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(CalculatorPage);
