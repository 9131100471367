import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationDE from './locale/locale.de';
import translationEN from './locale/locale.en';
import sharedConfig from '../shared/sharedConfig';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  'en-UK': {
    translation: translationEN
  },
  'de-DE': {
    translation: translationDE
  }
};

let languageToSet = 'de-DE';

if (typeof window !== 'undefined') {
  for (let language of sharedConfig.LANGUAGES) {
    if (language.language) {
      if (window.location.pathname.indexOf(language.language) > -1) {
        languageToSet = language.language;
      }
    }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: languageToSet,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
